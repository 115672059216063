import React, { useState } from 'react';
import { Modal, Row, Col, Form, Input, Button, Select, Typography, message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../context/modalsSlice';
import { X } from '@phosphor-icons/react';
import { useAuth } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
const NewAngelModal: React.FC = () => {
    const auth = useAuth()
    const dispatch = useDispatch()
    const { addAngelModal } = useSelector((state: any) => state.modals)

    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    return (
        <Modal
            open={addAngelModal}
            footer={null}
            closable={false}
            closeIcon={<></>}
            title={<Row align="middle" justify="space-between">
                <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>Add angel</Typography.Title>
                <X onClick={() => dispatch(closeModal('addAngelModal'))} size={20} style={{ cursor: 'pointer' }} />
            </Row>}
            onCancel={() => {
                dispatch(closeModal('addAngelModal'))
            }}
        >
            <Form
                style={{ marginTop: '20px' }}
                form={form}
                layout="vertical"
                onFinish={async (values) => {
                    if (!auth?.currentUser) return message.error('You need to be logged in to publish a VC')
                    const id = uuidv4();
                    const prepared = {
                        ...values,
                        id,
                        public: false,
                        publisher: auth?.currentUser.uid,
                        resourceMembers: [auth?.currentUser.uid],
                        timestamp: new Date().valueOf()
                    }
                    //remove undefined values
                    Object.keys(prepared).forEach(key => prepared[key] === undefined && delete prepared[key])
                    try {
                        setLoading(true)
                        await setDoc(doc(getFirestore(), 'angels', id), prepared)
                        notification.success({ message: 'Angel Saved', description: 'The Angel has been saved privately.' })
                        setLoading(false)
                        form.resetFields()
                        dispatch(closeModal('addAngelModal'))
                        // close()
                    } catch (error: any) {
                        setLoading(false)
                        message.error('Error creating Angel ' + error?.message ?? '')
                    }
                }}
            >
                <Row gutter={12}>
                    <Col xs={24}>
                        <Form.Item label="Angel Name" name={'name'} rules={[{ required: true, message: 'Please input the name of the VC' }]}>
                            <Input placeholder='Ashton Kutcher ' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} >
                        <Form.Item label="Angel Email" name={'email'} rules={[{ required: true, type: 'email', message: 'Add a valid url' }]}>
                            <Input placeholder='@' />
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={12}>
                        <Form.Item label="LinkedIn" name={'linkedIn'} rules={[{ required: false, type: 'url', message: 'Add a valid url' }]}>
                            <Input placeholder='linkedin/in/___ ' />
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={12}>
                        <Form.Item label="Stage" name="stage">
                            <Select
                                options={[
                                    { label: 'Pre-Seed', value: 'preseed' },
                                    { label: 'Seed', value: 'seed' },
                                    { label: 'Pre-Series A', value: 'preseriesa' },
                                    { label: 'Series A', value: 'seriesa' },
                                    { label: 'Series B', value: 'seriesb' },
                                ]} mode="tags" placeholder="Stage" />
                        </Form.Item>
                    </Col>

                    <Col xs={24}
                        style={{ marginTop: '20px' }}
                    >
                        <Button loading={loading} type="primary" htmlType="submit" block>Create Angel</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default NewAngelModal;