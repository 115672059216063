import React, { useState } from 'react';
import { Row, Col, Modal, Typography, Tag, message, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../context/modalsSlice';
import dayjs from 'dayjs';
import { ArrowLeft, CaretLeft, Trash } from '@phosphor-icons/react';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

const ContactEventDetailsModal: React.FC = () => {
    // Your component logic here
    const dispatch = useDispatch()
    const { contactEvent } = useSelector((state: any) => state.data)
    const { viewContactDetailsModal } = useSelector((state: any) => state.modals)

    const [loading, setLoading] = useState<string[]>([])

    if (!contactEvent) return <></>

    return (
        <Modal title={null}
            footer={null}
            open={viewContactDetailsModal}
            closable={false}
            style={{ top: 20 }}
            closeIcon={null}
            onCancel={() =>
                dispatch(closeModal('viewContactDetailsModal'))
            }>
            <Row>
                <Col xs={24}>
                    {/* Left content */}
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', width: '100%' }}
                    >
                        <Row justify="space-between" align="middle"
                            style={{ width: '100%' }}
                        >
                            <Row align="middle">
                                <CaretLeft size={20}
                                    style={{
                                        cursor: 'pointer',
                                        marginRight: '10px'
                                    }}
                                    onClick={() => dispatch(closeModal('viewContactDetailsModal'))} />
                                <Typography.Title
                                    style={{
                                        margin: '0px',
                                        padding: '0px',
                                        flex: 1
                                    }}
                                    level={5}>Contact with <span className='primary'>{contactEvent?.person?.name}</span></Typography.Title>
                            </Row>
                            {loading.includes('delete')

                                ? <Spin /> : <Trash
                                    style={{ cursor: 'pointer' }}
                                    size={20} onClick={async () => {
                                        setLoading([...loading, 'delete'])
                                        try {
                                            await deleteDoc(doc(getFirestore(), 'touchpointsContacts', contactEvent.id))
                                            message.success('Contact event deleted')
                                            setLoading(loading.filter(l => l !== 'delete'))
                                            dispatch(closeModal('viewContactDetailsModal'))
                                        } catch (error: any) {
                                            notification.error({ message: 'Error deleting contact event', description: error.message })
                                            setLoading(loading.filter(l => l !== 'delete'))
                                        }


                                    }} />}
                        </Row>
                        <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }}>Reached on <span className='primary'>{dayjs(contactEvent?.timestamp).format('MMM DD YYYY')}</span> vía <Tag color="blue">{contactEvent?.source}</Tag></Typography.Text>
                    </div>
                </Col>
                <Col span={12}>
                    {/* Right content */}
                </Col>
            </Row>
        </Modal>
    );
};

export default ContactEventDetailsModal;