import { configureStore } from "@reduxjs/toolkit";
import userReducer from '../context/userSlice'
import modalsReducer from "../context/modalsSlice";
import dataReducer from "../context/dataSlice";
const store = configureStore({
    reducer: {

        user: userReducer,
        modals: modalsReducer,
        data: dataReducer

    }
});

export default store;