import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../context/userSlice";
import { Layout, Typography } from "antd";


function AuthScreensWrapper({ children }: { children: JSX.Element }) {
    const dispatch = useDispatch()
    const auth = getAuth()
    const { user } = useSelector((state: any) => state.user)


    const getUser = async () => {
        if (!auth.currentUser) return
        await new Promise((resolve) => setTimeout(resolve, 3000))
        const userraw = (await getDoc(doc(getFirestore(), 'users', auth.currentUser.uid))).data()
        const activeRounds = await getDocs(query(collection(getFirestore(), 'rounds'), where('status', '==', 'active'), where('resourceMembers', 'array-contains', userraw?.uid)))
        const company = await getDocs(
            query(
                collection(getFirestore(), 'companies'),
                where('resourceMembers', 'array-contains', userraw?.uid)
            )
        )
        if (!userraw) return
        dispatch(setUser({
            ...userraw,
            rounds: activeRounds?.docs?.map(doc => doc.data()) ?? [],
            company: company?.empty ? null : company?.docs[0].data()
        }))
    }

    useEffect(() => {
        if (!user?.uid) {
            getUser();
        }


    }, [user?.uid])


    if (!user.uid) {
        return (
            <Layout>
                <Layout.Content
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fafbfd',
                        height: '100vh',
                        width: '100vw'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Typography.Text>Loading...</Typography.Text>
                        <Typography.Text type="secondary">If this takes too long, please refresh the page</Typography.Text>
                    </div>
                </Layout.Content>
            </Layout>
        )
    }


    return (

        <>
            {children}
        </>
    );
}


export default AuthScreensWrapper;