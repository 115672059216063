import React, { useState } from 'react';
import { Modal, Row, Col, Typography, Divider, Tooltip, Descriptions, Tag, Select, Form, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../context/modalsSlice';
import { setData } from '../context/dataSlice';
import { HandTap, Pencil } from '@phosphor-icons/react';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';


const InvestorModal: React.FC = () => {
    // Your component logic here
    const dispatch = useDispatch();
    const { viewInvestorModal } = useSelector((state: any) => state.modals);
    const { investor: preInvestor } = useSelector((state: any) => state.data);

    const [loading, setLoading] = useState<string[]>([])



    if (!preInvestor) return <></>;

    let investor = {
        linkedIn: 'NO LINKEDIN',
        stage: [],
        ...preInvestor,
        emailContact: preInvestor.emailContact || preInvestor.email || 'NO EMAIL',
    }
    delete investor['NO_ID_FIELD']
    delete investor['email']
    // investor keys by desc

    investor = Object.keys(investor).sort().reduce(
        (obj: any, key: string) => {
            obj[key] = investor[key];
            return obj;
        },
        {}
    );

    const ignore = ['NO_ID_FIELD', 'id', 'resource', 'public', 'type', 'publisher', 'name', 'deletedMatches', 'resourceMembers']
    const ReturnableItems = ({ key, value }: { key: string, value: any }) => {
        let tobereturned = value
        switch (key) {
            case 'timestamp':
                tobereturned = new Date(value).toDateString()
                break;

            case 'link':
                tobereturned = <a className='primary' href
                    ={value} target="_blank" rel="noreferrer"> <Typography.Text
                        className='primary'
                        editable={{
                            onChange: async (e) => {
                                if (loading.includes(key)) {
                                    return
                                }
                                setLoading((pre) => [...pre, key])

                                await new Promise((resolve) => setTimeout(resolve, 100))

                                try {
                                    await updateDoc(doc(getFirestore(), investor.resource), {
                                        [key]: e
                                    })
                                    dispatch(setData({
                                        item: 'investor',
                                        data: {
                                            ...investor,
                                            [key]: e
                                        }
                                    }))
                                    setLoading((pre) => pre.filter((p) => p !== key))
                                } catch (error) {
                                    console.log(error);
                                    message.error(`Error updating ${key}`)
                                }
                            },
                            tooltip: 'Edit contact..',
                            icon: <Pencil size={12} />
                        }}
                    >
                        {value}
                    </Typography.Text></a>

                return tobereturned

            case 'linkedIn':
                tobereturned = value === 'NO LINKEDIN' ? value : value
                if (value !== 'NO LINKEDIN') {
                    tobereturned = <a className='primary' href
                        ={value} target="_blank" rel="noreferrer"> <Typography.Text
                            className='primary'
                            editable={{
                                onChange: async (e) => {
                                    if (loading.includes(key)) {
                                        return
                                    }
                                    setLoading((pre) => [...pre, key])

                                    await new Promise((resolve) => setTimeout(resolve, 100))

                                    try {
                                        await updateDoc(doc(getFirestore(), investor.resource), {
                                            [key]: e
                                        })
                                        dispatch(setData({
                                            item: 'investor',
                                            data: {
                                                ...investor,
                                                [key]: e
                                            }
                                        }))
                                        setLoading((pre) => pre.filter((p) => p !== key))
                                    } catch (error) {
                                        console.log(error);
                                        message.error(`Error updating ${key}`)
                                    }
                                },
                                tooltip: 'Edit contact..',
                                icon: <Pencil size={12} />
                            }}
                        >
                            {value}
                        </Typography.Text></a>

                    return tobereturned
                }
                break
            case 'emailContact':
                tobereturned = value === 'NO EMAIL' ? value : value
                if (value !== 'NO EMAIL') {
                    tobereturned = <a href
                        ={`mailto:${value}`} target="_blank" rel="noreferrer">
                        <Typography.Text
                            className='primary'
                            editable={{

                                onChange: async (e) => {
                                    if (loading.includes(key)) {
                                        return
                                    }
                                    setLoading((pre) => [...pre, key])

                                    await new Promise((resolve) => setTimeout(resolve, 100))

                                    try {
                                        await updateDoc(doc(getFirestore(), investor.resource), {
                                            [key]: e
                                        })
                                        dispatch(setData({
                                            item: 'investor',
                                            data: {
                                                ...investor,
                                                [key]: e
                                            }
                                        }))
                                        setLoading((pre) => pre.filter((p) => p !== key))
                                    } catch (error) {
                                        console.log(error);
                                        message.error(`Error updating ${key}`)
                                    }

                                },
                                tooltip: 'Edit contact..',
                                icon: <Pencil size={12} />

                            }}
                        >
                            {value}
                        </Typography.Text>
                    </a>
                    return tobereturned
                }
                break
            case 'stage':
                tobereturned = <Form.Item name="stage">
                    <Select
                        onChange={async (v: any) => {
                            if (loading.includes('stage')) {
                                return
                            }
                            setLoading((pre) => [...pre, 'stage'])

                            await new Promise((resolve) => setTimeout(resolve, 100))


                            try {
                                await updateDoc(doc(getFirestore(), investor.resource), {
                                    stage: v
                                })
                                setLoading((pre) => pre.filter((p) => p !== 'stage'))
                            } catch (error: any) {
                                console.log(error);
                                message.error('Error updating stage ' + error.message)
                                setLoading((pre) => pre.filter((p) => p !== 'stage'))

                            }
                        }}
                        defaultValue={value}
                        mode="tags"
                        style={{
                            maxWidth: '350px',
                            minWidth: '200px',
                            height: 'auto',
                            flex: 1,
                            display: window.innerWidth < 900 ? 'none' : 'block'
                        }}
                        loading={loading.includes('stage')}
                        placeholder="Stage"
                        options={[
                            //ADD TYPES OF FUNDS 
                            { label: 'Pre-Seed', value: 'preseed' },
                            { label: 'Seed', value: 'seed' },
                            { label: 'Pre-Series A', value: 'preseriesa' },
                            { label: 'Series A', value: 'seriesa' },
                            { label: 'Series B', value: 'seriesb' },

                        ]} />
                </Form.Item>
                return tobereturned
            default:
                break;
        }

        return <Typography.Text type="secondary" style={{ fontSize: '12px' }}
            editable={key === 'timestamp' ? false : {
                onChange: async (e) => {
                    if (loading.includes(key)) {
                        return
                    }
                    setLoading((pre) => [...pre, key])

                    await new Promise((resolve) => setTimeout(resolve, 100))

                    try {
                        await updateDoc(doc(getFirestore(), investor.resource), {
                            [key]: e
                        })
                        dispatch(setData({
                            item: 'investor',
                            data: {
                                ...investor,
                                [key]: e
                            }
                        }))
                        setLoading((pre) => pre.filter((p) => p !== key))
                    } catch (error) {
                        console.log(error);
                        message.error(`Error updating ${key}`)
                    }

                },
                tooltip: 'Edit contact..',
                icon: <Pencil size={12} />

            }}
        >{tobereturned}</Typography.Text>
    }
    const KeyTitle = ({ key }: { key: string }) => {
        switch (key) {
            case 'timestamp':
                return 'Published Date'
            case 'link':
                return 'Link'
            case 'linkedIn':
                return 'LinkedIn'
            case 'emailContact':
                return 'Email'
            case 'stage':
                return 'Stage'

            default:
                return key
        }
    }
    const items: DescriptionsItemType[] = Object.keys(investor).filter((k) =>
        ignore.indexOf(k) === -1
    ).map((key: string) => {
        return {
            label: <Typography.Text
                style={{
                    fontSize: '12px', fontWeight: 'bold'
                }}
            > {KeyTitle({ key })}</Typography.Text >,
            content: investor[key],
            children: ReturnableItems({
                key,
                value: investor[key]
            })
        }
    });

    return (
        <Modal
            style={{ top: 30 }}
            width={window.innerWidth > 768 ? '70%' : '100%'}
            open={viewInvestorModal}

            title={null}
            footer={null}
            onCancel={() => {
                // Your logic here
                dispatch(closeModal('viewInvestorModal'))
                dispatch(setData({
                    item: 'investor',
                    data: null
                }))
            }}
        >
            <Row>
                <Col xs={24}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'start'
                    }}
                >
                    {/* Your content here */}
                    <div style={{
                        borderRadius: '50%',
                        border: '3px solid #19DB8A',
                        width: 20,
                        height: 20,
                    }}>
                    </div>
                    <Typography.Text type="secondary" style={{ fontSize: '12px', marginTop: '10px' }}>{
                        investor?.resource?.includes('vcs') ? 'Venture Capitalist' : (investor?.resource?.includes('angels') ? 'Angel Investor' : 'Investor')
                    }</Typography.Text>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{
                            marginTop: '5px'
                        }}

                    >
                        <Typography.Title level={5} style={{ margin: "0px", padding: "0px" }}>{investor?.name}</Typography.Title>
                        <Tooltip title="Start a touchpoint">
                            <HandTap size={20}
                                className='primary'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    dispatch(closeModal('viewInvestorModal'))
                                    dispatch(openModal('newTouchPointModal'))
                                    dispatch(setData({
                                        item: 'investor', data: {
                                            ...investor, type: investor.resource.includes('vcs') ? 'vc' : 'angel'

                                            , resource: investor.resource
                                        }
                                    }))
                                }}
                            />
                        </Tooltip>
                    </Row>
                    <Divider />
                    <Form layout="vertical" name="basic">

                        <Descriptions title="" layout="vertical" bordered items={items} />
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};

export default InvestorModal;