import React from 'react';
import { Form, Col, Row, Typography, Input, Card, Space, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid'
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { setUser } from '../context/userSlice';
import { PlusCircle, X } from '@phosphor-icons/react';

const Company: React.FC = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)
    const company = user?.company || {}



    const FinishForm = async (values: any) => {

        const id = user?.company?.id || uuidv4()
        let companyOnForm = {
            ...(company || {}),
            ...form.getFieldsValue(),
            id
        }
        // console.log(companyOnForm);

        // delete possible undefined fields in metadata

        if (companyOnForm.metadata) {
            companyOnForm.metadata = companyOnForm.metadata.filter((item: any) => item.key && item.value)
        } else {
            delete companyOnForm.metadata
        }

        try {
            if (company?.id) {
                // update
                await updateDoc(doc(getFirestore(), 'companies', id), companyOnForm)
            } else {
                // create
                await setDoc(doc(getFirestore(), 'companies', id), {
                    ...companyOnForm, timestamp: new Date().valueOf(),
                    owner: user?.uid,
                    resourceMembers: [user?.uid]
                }, {
                    merge: true
                })
            }

            dispatch(setUser({ ...user, company: companyOnForm }))
            message.success('Company saved')
        } catch (error: any) {
            message.error('Error saving company ' + error?.message)
        }
    }


    return (
        <Form form={form} layout="vertical"
            initialValues={company}
            onFinish={FinishForm}
        >
            {/* Add your form fields here */}
            <Row gutter={12}>
                <Col xs={24}
                    style={{ marginBottom: '25px' }}
                >
                    <Typography.Title level={5}
                        style={{ margin: '0px', padding: '0px' }}
                    >Company Information</Typography.Title>
                </Col>
                <Col xs={24} lg={12}
                >
                    <Form.Item>
                        <Form.Item
                            label="Company Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your company name!' }]}
                        >
                            <Input placeholder='Aviato' />
                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}
                >
                    <Form.Item>
                        <Form.Item
                            label="Company Website"
                            name="link"
                            rules={[{ required: true, message: 'Please input your company website!', type: 'url' }]}
                        >
                            <Input placeholder='https://aviato.com' />
                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col xs={24}
                >
                    <Form.Item>
                        <Form.Item
                            label="About your company"
                            name="about"
                            rules={[{ required: true, message: 'Please input your company about!' }]}
                        >
                            <Input.TextArea placeholder='Pitch your company, less is more..' />
                        </Form.Item>
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <label>Extra information</label>
                    <div style={{ marginBottom: '25px' }} ></div>
                    <Form.List name="metadata"

                    >
                        {(fields, { add, remove }) => (
                            <div style={{ width: '100%' }}>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="baseline">
                                        <Form.Item
                                            style={{ flex: 1 }}
                                            name={[name, 'key']}
                                            rules={[{ required: true, message: 'Missing first name' }]}
                                        >
                                            <Input placeholder="Value Key" />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ flex: 1 }}
                                            name={[name, 'value']}
                                            rules={[{ required: true, message: 'Missing last name' }]}
                                        >
                                            <Input placeholder="Value" />
                                        </Form.Item>
                                        <X onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusCircle />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form.List>
                </Col>
                <Col xs={24}>
                    <Row justify="end">
                        <div>
                            <Button type="primary" htmlType="submit" >Save company</Button>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Form >
    );
};

export default Company;