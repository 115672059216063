import React from 'react';
import { Modal, Col, Row, Typography, Popover, DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../context/modalsSlice';
import { useFirestoreDocData } from 'reactfire';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { Pencil } from '@phosphor-icons/react';
import { updateData } from '../context/dataSlice';
import { setUser } from '../context/userSlice';
import dayjs from 'dayjs';



const CurrentRoundDetails: React.FC = () => {
    const dispatch = useDispatch();
    const { showRoundDetails } = useSelector((state: any) => state.modals);
    const { user } = useSelector((state: any) => state.user);
    const round = user?.rounds.length > 0 ? user?.rounds[0] : null;

    const { status, data } = useFirestoreDocData(doc(getFirestore(), 'rounds', round?.id))
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: round?.currency ?? 'USD',
    });
    // Your component logic goes here

    return (
        // Your JSX code goes here
        <Modal
            open={showRoundDetails}
            onCancel={() => dispatch(closeModal('showRoundDetails'))}
            title={null}
            footer={null}
        >

            {
                status === 'loading' && <p>Loading...</p>
            }

            {
                status === 'success' &&
                <Row>
                    <Col xs={24}>
                        <Row align="middle">
                            <div style={{
                                borderRadius: '50%',
                                border: '3px solid #19DB8A',
                                width: 20,
                                height: 20,
                                marginRight: '5px'
                            }}>
                            </div>
                            <Typography.Title
                                editable={{
                                    onChange: async (e) => {
                                        await updateDoc(doc(getFirestore(), 'rounds', data?.id), { name: e });
                                        dispatch(setUser({ ...user, rounds: user.rounds.map((r: any) => r.id === data?.id ? { ...r, name: e } : r) }));
                                    },
                                    icon: <Pencil size={16} className='primary' />
                                }}
                                style={{ margin: 0, padding: 0, marginLeft: '5px' }} level={4}>{data?.name}</Typography.Title>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingLeft: '25px',
                            marginLeft: '12px',
                            borderLeft: '2px solid #19DB8A',
                        }}>
                            <Typography.Text
                                style={{ marginTop: '15px' }}
                            >Expected closing date: <Popover content={
                                <div style={{ width: '150px' }}>
                                    <DatePicker defaultValue={dayjs(data?.closingDate)} onChange={async (date: any) => {
                                        await updateDoc(doc(getFirestore(), 'rounds', data?.id), { closingDate: date.valueOf() });
                                        dispatch(setUser(
                                            {
                                                ...user,
                                                rounds: user.rounds.map((r: any) => r.id === data?.id ? { ...r, closingDate: date.valueOf() } : r)
                                            }
                                        ));
                                    }} />
                                </div>
                            }>

                                    <span className='primary' style={{ fontWeight: 'bold' }}>{
                                        dayjs(data?.closingDate).format('MMM DD YYYY')
                                    }</span></Popover></Typography.Text>

                            <Typography.Text
                                style={{ marginTop: '15px' }}>
                                CAP : <Typography.Text
                                    className='primary'
                                    style={{ fontWeight: 'bold' }}
                                    editable={{
                                        onChange: async (e) => {
                                            let cap = parseFloat(e?.replace(/[^0-9.-]+/g, ""));
                                            await updateDoc(doc(getFirestore(), 'rounds', data?.id), { cap });
                                            dispatch(setUser(
                                                {
                                                    ...user,
                                                    rounds: user.rounds.map((r: any) => r.id === data?.id ? { ...r, cap } : r)
                                                }
                                            ));
                                        },
                                        icon: <Pencil size={16} className='primary' />
                                    }}

                                >{formatter.format(data.cap)}</Typography.Text>
                            </Typography.Text>
                            <Typography.Text
                                style={{ marginTop: '15px' }}>
                                Raising : <Typography.Text
                                    className='primary'
                                    style={{ fontWeight: 'bold' }}
                                    editable={{
                                        onChange: async (e) => {
                                            let target = parseFloat(e?.replace(/[^0-9.-]+/g, ""));
                                            await updateDoc(doc(getFirestore(), 'rounds', data?.id), { target });
                                            dispatch(setUser(
                                                {
                                                    ...user,
                                                    rounds: user.rounds.map((r: any) => r.id === data?.id ? { ...r, target } : r)
                                                }
                                            ));
                                        },
                                        icon: <Pencil size={16} className='primary' />
                                    }}

                                >{formatter.format(data.target)}</Typography.Text>
                            </Typography.Text>

                            <Typography.Text
                                style={{ marginTop: '15px' }}
                            >Status: <Popover content={
                                <div style={{ width: '150px' }}>
                                    <Select
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: 'Active', value: 'active' },
                                            { label: 'Closed', value: 'closed' },
                                        ]}
                                        defaultValue={data.status}
                                        onChange={async (status) => {
                                            await updateDoc(doc(getFirestore(), 'rounds', data?.id), { status });
                                            dispatch(setUser(
                                                {
                                                    ...user,
                                                    rounds: user.rounds.map((r: any) => r.id === data?.id ? { ...r, status } : r)
                                                }
                                            ));
                                        }} />
                                </div>
                            }>

                                    <span className='primary' style={{ fontWeight: 'bold' }}>{
                                        data.status
                                    }</span></Popover></Typography.Text>

                            <Typography.Text
                                style={{ marginTop: '15px' }}
                            >Currency: <Popover content={
                                <div style={{ width: '150px' }}>
                                    <Select
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: 'USD', value: 'USD' },
                                            { label: 'EUR', value: 'EUR' },
                                            { label: 'GBP', value: 'GBP' },
                                            { label: 'MXN', value: 'MXN' }
                                        ]}
                                        defaultValue={data.currency}
                                        onChange={async (currency) => {
                                            await updateDoc(doc(getFirestore(), 'rounds', data?.id), { currency });
                                            dispatch(setUser(
                                                {
                                                    ...user,
                                                    rounds: user.rounds.map((r: any) => r.id === data?.id ? { ...r, currency } : r)
                                                }
                                            ));
                                        }} />
                                </div>
                            }>

                                    <span className='primary' style={{ fontWeight: 'bold' }}>{
                                        data.currency
                                    }</span></Popover></Typography.Text>
                        </div>
                    </Col>
                </Row>
            }

        </Modal>
    );
};

export default CurrentRoundDetails;