import React, { useState } from 'react';
import { Alert, Button, Card, Col, Input, Popconfirm, Row, Select, Space, Table, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { collection, deleteDoc, doc, getFirestore, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { setUser } from '../context/userSlice';
import { useFirestoreCollectionData } from 'reactfire';
import { openModal } from '../context/modalsSlice';
import { Plus, Trash } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { setData } from '../context/dataSlice';



const TouchpointsTable = () => {

    const { user } = useSelector((state: any) => state.user)
    const round = user?.rounds?.length > 0 ? user?.rounds[0] : null


    const { status, data } = useFirestoreCollectionData(query(collection(getFirestore(), 'touchpoints'),
        where('resourceMembers', 'array-contains', user.uid),
        where('roundId', '==', round?.id ?? 'NoRound'),
        // orderBy('lastContact', 'desc')
    ))

    const [tpstatus, setTPStatus] = useState<any>([])
    const [search, setSearch] = useState<string>("")

    const dispatch = useDispatch()








    return <Col xs={24} >

        <Row justify="space-between" align="middle">
            <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>Touchpoints</Typography.Title>
            <Row style={{ flex: 1 }} justify="end">
                <Input.Search style={{ maxWidth: '20%', marginRight: '10px' }}
                    onChange={(e) => {
                        console.log(e);
                        setSearch(e.target.value)
                    }}
                    placeholder="Search" />
                <Select
                    mode="tags"
                    style={{
                        maxWidth: '350px',
                        minWidth: '200px',
                    }}
                    onChange={(e) => {
                        console.log(e);
                        setTPStatus(e)

                    }}
                    placeholder="Status" options={[
                        //ADD TYPES OF FUNDS 
                        { label: 'Building Relationship', value: 'Building Relationship' },
                        { label: 'Follow Up', value: 'Follow Up' },
                        { label: 'Pitched', value: 'Pitched' },
                        { label: 'Negotiation', value: 'Negotiation' },
                        { label: 'Passed', value: 'Passed' },
                        { label: 'Deal Closed 🚀', value: 'Deal Closed' },


                    ]} />
                {/* <Button hidden onClick={() => {
                    dispatch(openModal('newTouchPoint'))
                }} shape="circle" type="primary" style={{ marginLeft: '10px' }} icon={<Plus size={20} />} /> */}
            </Row>
        </Row>
        <Table
            loading={status === 'loading'}
            scroll={{ x: 400 }}
            pagination={{
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showSizeChanger: true,
            }}
            columns={[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text: string, record: any) => <a href={record
                        .link} target="_blank">{<Typography.Text
                            onClick={() => {
                                dispatch(openModal('openTouchPointDetails'))
                                dispatch(setData({
                                    item: 'touchpoint',
                                    data: record
                                }))
                            }}
                            className='primary'
                            style={{
                                fontWeight: 600,
                                color: 'black',
                                fontSize: '12px'
                            }}>{text}</Typography.Text>}</a>
                },
                {
                    title: 'Initiated',
                    dataIndex: 'relationInitiated',
                    key: 'relationInitiated',
                    render: (text: string, record: any) => <a href={record
                        .link} target="_blank">{<Typography.Text style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px'
                        }}>{dayjs(record.relationInitiated).format('DD MMM YYYY')}</Typography.Text>}</a>
                },
                {
                    title: 'Last Contact',
                    dataIndex: 'lastContact',
                    key: 'lastContact',
                    render: (text: string, record: any) => {
                        const timePassed = dayjs().diff(dayjs(record.lastContact), 'day')


                        return <Tag
                            color={
                                !record?.lastContact ? 'orange' :
                                    record?.status === 'Deal Closed' ? 'green' :
                                        timePassed > 30 ? 'red' :
                                            timePassed > 15 ? 'orange' :
                                                timePassed > 7 ? 'blue' :
                                                    'green'
                            }
                        >{!record?.lastContact ? 'Unknown' : dayjs(record.lastContact).format('DD MMM YYYY')}</Tag>
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    filters: [
                        { text: 'Building Relationship', value: 'Building Relationship' },
                        { text: 'Follow Up', value: 'Follow Up' },
                        { text: 'Pitched', value: 'Pitched' },
                        { text: 'Negotiation', value: 'Negotiation' },
                        { text: 'Passed', value: 'Passed' },
                        { text: 'Deal Closed', value: 'Deal Closed' },
                    ],
                    onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
                    render: (text: string, record: any) => {
                        return <Tag color={
                            record.status === 'Passed' ? 'red' :
                                record.status === 'Deal Closed' ? 'green' :
                                    record.status === 'Building Relationship' ? 'orange' :
                                        'geekblue'
                        }>{text}</Tag>
                    }
                },
                {
                    title: 'Actions',
                    dataIndex: 'actions',
                    key: 'actions',
                    render: (text: string, record: any) => <Space>
                        {!record.lastContact && <Popconfirm
                            title="Are you sure you want to delete this touchpoint?"
                            okButtonProps={{ danger: true, block: false }}
                            okText="Yes"
                            onConfirm={async () => {
                                await deleteDoc(doc(getFirestore(), 'touchpoints', record.id))
                            }} >
                            <Trash size={20} style={{ cursor: 'pointer' }} />

                        </Popconfirm>}
                    </Space>
                },


            ]} dataSource={data?.filter((tp: any) =>
                // has any of tpStatus
                tpstatus.includes(tp?.status) || tpstatus.length === 0 &&
                // has any of search
                (tp.name.toLowerCase().includes(search.toLowerCase()) ||



                    tp.resourceMembers?.includes(search.toLowerCase())
                )

            ).sort((a: any, b: any) => {
                return (a.lastContact ?? 0) < (b.lastContact ?? 0) ? 1 : -1
            })

            } />
    </Col >

}


const Touchpoints: React.FC = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)

    return (
        <div>
            <Row>
                {!user.settings?.removeTouchPointsNotice && <Col xs={24} style={{ marginBottom: '6px' }}>
                    <Alert message="Touchpoints are initiated conversations with any possible investor, this resource is private." type="info" closable onClose={async () => {
                        await updateDoc(doc(getFirestore(), 'users', user.uid), { "settings.removeTouchPointsNotice": true })
                        dispatch(setUser({ ...user, settings: { ...user.settings, removeFundsPublicNotice: true } }))
                    }}
                        style={{ marginBottom: '25px' }}
                    />
                </Col>
                }
                {/* <Col xs={24}>
                    <Row>
                        <Card title="Card title" bordered={false}>
                            Card content
                        </Card>
                    </Row>
                </Col> */}
                <TouchpointsTable />
                {/* <Col span={12} xs={24}>
               
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="Card title" bordered={false}>
                                Card content
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Card title" bordered={false}>
                                Card content
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="Card title" bordered={false}>
                                Card content
                            </Card>
                        </Col>
                    </Row>
                </Col> */}
            </Row>
        </div>
    );
};

export default Touchpoints;