import React, { useEffect, useState } from 'react';
import { Col, Row, Drawer, Typography, Tag, Timeline, Modal, Form, DatePicker, Select, Input, Rate, Button, notification, message, Tooltip, Table, Space, Checkbox, InputNumber, Popover, Popconfirm, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../context/modalsSlice';
import { setData } from '../context/dataSlice';
import dayjs from 'dayjs';
import { Check, CircleNotch, Clock, Envelope, PlusCircle, Smiley, SmileyMeh, SmileyNervous, SmileySad, Ticket, Trash, UserPlus } from '@phosphor-icons/react';
import { useFirestoreCollectionData } from 'reactfire';
import { collection, deleteDoc, doc, getDocs, getFirestore, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import TextArea from 'antd/es/input/TextArea';
import { v4 as uuid } from 'uuid';
import { getFunctions, httpsCallable } from 'firebase/functions';

const customIcons: Record<number, React.ReactNode> = {
    1: <SmileySad size={22} />,
    2: <SmileyNervous size={22} />,
    3: <SmileyMeh size={22} />,
    4: <Smiley size={22} />,
    5: <Smiley size={22} />
};

const AddTouchPointContact = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)
    const company = user?.company ?? {}
    const round = user?.rounds ? user.rounds[0] : {}
    const { touchpoint } = useSelector((state: any) => state.data)
    const inititated = dayjs(touchpoint?.relationInitiated)
    const [addTPContact, setAddTPContact] = useState(false)
    const [addTicket, setAddTicket] = useState(false)
    const [addEmail, setAddEmail] = useState(false)
    const [form] = Form.useForm()
    const [ticketForm] = Form.useForm()
    const [emailForm] = Form.useForm()

    const [loading, setLoading] = useState(false)

    const checkemailswithcontacts = httpsCallable(getFunctions(), 'checkemailswithcontacts')



    const NewContactModal = () => {
        return <Modal
            style={{ top: '10px' }}
            open={addTPContact}
            onCancel={() => {
                form.resetFields()
                setAddTPContact(false)
            }}
            title={null}
            footer={null}
            destroyOnClose={true}
        >
            <Form
                layout='vertical'
                form={form}
                onFinish={async (values) => {
                    const id = uuid()
                    const person = touchpoint?.persons.find((p: any) => values.person)

                    const prepared = {
                        id,
                        ...values,
                        emotionalStamina: values.emotionalStamina || 3,
                        timestamp: values.timestamp.valueOf(),
                        touchpointId: touchpoint.id,
                        person: {
                            name: person?.name ?? touchpoint?.name ?? 'Unknown',
                            email: person?.email ?? touchpoint?.email ?? 'Unknown',
                            resource: person?.id ? `persons/${person?.id}` : touchpoint?.relation
                        }

                    }
                    //REMOVE UNDEFINED VALUES
                    Object.keys(prepared).forEach(key => prepared[key] === undefined && delete prepared[key])
                    delete prepared['person.name']
                    delete prepared['person.email']
                    // console.log(prepared);


                    try {
                        await setDoc(doc(getFirestore(), 'touchpointsContacts', id), prepared)

                        setLoading(false)
                        notification.success({
                            message: 'Contact event added',
                            description: 'The contact event was added successfully'
                        })
                        form.resetFields()
                        return setAddTPContact(false)

                    } catch (error: any) {
                        message.error('There was an error adding the contact event ' + error.message)
                        setLoading(false)
                    }

                }}
            >
                <Row gutter={12}>
                    <Col xs={24}>
                        <Row>
                            <Typography.Title level={5} style={{ margin: 0, padding: 0, marginBottom: '25px' }}>Add a new contact event to this touchpoint</Typography.Title>
                        </Row>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name="timestamp" label="Event date"
                            rules={[{
                                required: true,
                                message: 'Please select a date'
                            }]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                disabledDate={(current) => {
                                    return current && current < inititated.add(-1, 'day')
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name="source" label="Contact Source"
                            rules={[{
                                required: true,
                                message: 'Please select a source'
                            }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={[
                                    { label: 'Email', value: 'email' },
                                    { label: 'Call', value: 'call' },
                                    { label: 'Meeting', value: 'meeting' },
                                    { label: 'In Person', value: 'inPerson' },
                                    { label: 'Other', value: 'other' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} >
                        <Row align="middle">
                            <Form.Item name="person" label="Person" style={{ flex: 1 }}>
                                <Select style={{ width: '100%' }}
                                    placeholder="Select a person"
                                    options={[...(touchpoint?.persons ?? []),
                                    (touchpoint?.relation?.includes('angels') ? {
                                        name: touchpoint.name,
                                        email: touchpoint.email,
                                        id: touchpoint.relation?.split('/')[1]
                                    } : {})]?.map((c: any) => {
                                        return {
                                            label: c.name,
                                            value: c.name
                                        }
                                    })} />
                            </Form.Item>
                            {/* <PlusCircle size={20}
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                                onClick={() => {
                                    dispatch(openModal('addPersonModal'))
                                }} /> */}
                        </Row>

                    </Col>
                    <Col xs={24}>
                        <Form.Item name="notes" label="Event Notes">
                            <TextArea
                                placeholder="Write some notes about this event"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} >
                        <Row justify="center"
                            onClick={() => {
                                // console.log(touchpoint);

                            }}
                        >
                            <Form.Item name="emotionalStamina" label={<label style={{ textAlign: 'center' }}>Emotional Stamina</label>} help="How do you feel?">
                                <Rate style={{
                                    color: 'var(--primary-color)',
                                }} character={({ index = 0 }) => customIcons[index + 1]} />
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '15px' }}>
                        <Button
                            loading={loading}
                            type="primary" htmlType="submit" block>Add Contact Event</Button>
                    </Col>
                </Row>

            </Form>
        </Modal >
    }

    const NewTicketModal = () => {
        return <Modal
            style={{
                top: '10px'
            }}
            open={addTicket}
            onCancel={() => {
                ticketForm.resetFields()
                setAddTicket(false)
            }}
            title={null}
            footer={null}
        >
            <Form
                layout='vertical'
                form={ticketForm}
                onFinish={async (values) => {
                    const id = uuid()
                    // console.log(round);

                    const prepared = {
                        id,
                        ...values,
                        timestamp: values.timestamp.valueOf(),
                        amount: values.amount,
                        deposited: values.deposited || false,
                        touchpointId: touchpoint.id,
                        roundId: round.id,

                    }

                    Object.keys(prepared).forEach(key => prepared[key] === undefined && delete prepared[key])

                    try {
                        await setDoc(doc(getFirestore(), 'tickets', id), prepared)

                        setLoading(false)
                        notification.success({
                            message: 'Ticket added',
                            description: 'Congrats on this great news! The ticket was added successfully.'
                        })
                        form.resetFields()
                        return setAddTicket(false)

                    } catch (error: any) {
                        message.error('There was an error adding the contact event ' + error.message)
                        setLoading(false)
                    }

                }}
            >
                <Row gutter={12}>
                    <Col xs={24}>
                        <Row>
                            <Typography.Title level={5} style={{ margin: 0, padding: 0, marginBottom: '25px' }}>🚀 Register a new ticket </Typography.Title>
                        </Row>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name="timestamp" label="Ticket Date"
                            rules={[{
                                required: true,
                                message: 'Please select a date'
                            }]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                disabledDate={(current) => {
                                    return current && current < inititated.add(-1, 'day')
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name="vehicle" label="Investment Vehicle"
                            rules={[{
                                required: true,
                                message: 'Please select a vehicle'
                            }]}
                        >
                            <Select
                                placeholder="Select a vehicle"
                                style={{ width: '100%' }}
                                options={[
                                    { label: 'Equity', value: 'equity' },
                                    { label: 'Convertible Note', value: 'convertibleNote' },
                                    { label: 'SAFE', value: 'safe' },
                                    { label: 'KISS', value: 'kiss' },
                                    { label: 'Other', value: 'other' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={18}>
                        <Form.Item name="amount" label="Amount">
                            <InputNumber placeholder="1,000,000"
                                min={0}
                                style={{ width: '100%' }}
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                        <Form.Item name="currency" label="Currency">
                            <Select style={{ width: '100%' }}
                                placeholder="Select a currency"
                                disabled
                                options={[
                                    { label: 'USD', value: 'usd' },
                                    { label: 'EUR', value: 'eur' },
                                    { label: 'GBP', value: 'gbp' },
                                    { label: 'Other', value: 'other' },
                                ]} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="deposited"
                            valuePropName="checked"
                        >
                            <Checkbox>The amount is already on your account?</Checkbox>
                        </Form.Item>
                    </Col>



                    <Col xs={24} style={{ marginTop: '15px' }}>
                        <Button
                            loading={loading}
                            type="primary" htmlType="submit" block>Create Ticket</Button>
                    </Col>
                </Row>

            </Form>
        </Modal>
    }


    const ref = React.useRef(null);

    const NewEmailModal = () => {
        return <Modal
            style={{
                top: '10px'
            }}
            open={addEmail}
            onCancel={() => {
                emailForm.resetFields()
                setAddEmail(false)
            }}
            title={null}
            footer={null}
        >
            <Form
                layout='vertical'
                form={emailForm}
                onFinish={async (values) => {
                    const id = uuid()
                    // console.log(round);

                    const prepared = {
                        id,
                        ...values,
                        timestamp: new Date().valueOf(),
                        owner: user.uid,
                        touchpointId: touchpoint.id,
                        roundId: round.id,
                        status: 'enqueued'

                    }

                    Object.keys(prepared).forEach(key => prepared[key] === undefined && delete prepared[key])

                    try {
                        await setDoc(doc(getFirestore(), 'emails', id), prepared)

                        setLoading(false)
                        notification.success({
                            message: 'Email enqueued',
                            description: 'We will send this email.'
                        })
                        emailForm.resetFields()
                        return setAddEmail(false)

                    } catch (error: any) {
                        message.error('There was an error requesting the email ' + error.message)
                        setLoading(false)
                    }

                }}
            >
                <Row gutter={12}>
                    <Col xs={24}>
                        <Row>
                            <Typography.Title level={5} style={{ margin: 0, padding: 0, marginBottom: '25px' }}>Send a new email </Typography.Title>
                        </Row>
                    </Col>
                    <Col xs={24} lg={24}>
                        <Form.Item name="subject" label="Subject"
                            rules={[{
                                required: true,
                                message: 'Please add a subject'
                            }]}
                        >
                            <Input placeholder="Intro to" />

                        </Form.Item>
                    </Col>
                    {!touchpoint?.relation?.includes('angels') && <Col xs={24} style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <label>Select a person</label>
                        <Select style={{ width: '100%' }}
                            placeholder="Select a person"
                            onSelect={(value) => {
                                const selected = touchpoint?.persons.find((p: any) => p.name === value)
                                emailForm.setFieldsValue({
                                    to: value,
                                    email: selected?.email
                                })
                            }}
                            options={[...(touchpoint?.persons ?? []),
                            (touchpoint?.relation?.includes('angels') ? {
                                name: touchpoint.name,
                                email: touchpoint.email,
                                id: touchpoint.relation?.split('/')[1]
                            } : {})]?.map((c: any) => {
                                return {
                                    label: c.name,
                                    value: c.name
                                }
                            }).filter((c: any) => c.value)
                                //remove empty values

                            } />
                    </Col>}
                    <Col xs={24} lg={12} >
                        <Form.Item name="to" label="Name"
                            rules={[{
                                required: true,
                                message: 'Please add a name'
                            }]}
                        >
                            <Input placeholder="Jane Doe" />

                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name="email" label="Email"
                            rules={[{
                                required: true,
                                message: 'Please add an email',
                                type: 'email'
                            }]}
                        >
                            <Input placeholder="@doe.com" />

                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.Item
                            label="Insert from company info"
                            name="infoWildcard"
                        >
                            <Select style={{ width: '100%' }}
                                onChange={(value) => {
                                    console.log(company);

                                    let prev = emailForm.getFieldValue('content')
                                    emailForm.setFieldsValue({
                                        content: (prev ?? '') + value
                                    })



                                }}
                                placeholder="Insert information"
                                options={[
                                    ...(company?.metadata?.map((c: any) => {
                                        return {
                                            label: c.key,
                                            value: c.value
                                        }
                                    }) ?? []),
                                    {
                                        label: 'Company Name',
                                        value: company?.name
                                    },
                                    {
                                        label: 'Company Website',
                                        value: company?.link
                                    },
                                    {
                                        label: 'Company About',
                                        value: company?.about ?? 'Unknown'
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={24}>
                        <Form.Item name="content" label="Content">
                            <Input.TextArea
                                rows={10}
                                ref={ref}
                                placeholder="Write your email here" />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.Item name="deposited"

                            valuePropName="checked"
                        >
                            <Checkbox>Send it?</Checkbox>
                        </Form.Item>
                    </Col>



                    <Col xs={24} style={{ marginTop: '15px' }}>
                        <Button
                            loading={loading}
                            type="primary" htmlType="submit" block>Enqueue email</Button>
                    </Col>
                </Row>

            </Form>
        </Modal>
    }


    return <>
        <NewContactModal />
        <NewTicketModal />
        <NewEmailModal />
        {loading ? <Spin /> : <Space>
            <Tooltip
                title='Add a new contact event to this touchpoint'
            >
                <PlusCircle className='primary' size={20}
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setAddTPContact(true)

                        form.setFieldValue('timestamp', dayjs())
                        form.setFieldValue('source', 'email')
                        if (touchpoint?.relation?.includes('angels')) {
                            form.setFieldValue('person.name', touchpoint?.name)
                            form.setFieldValue('person.email', touchpoint?.contact)
                        }
                    }}
                />
            </Tooltip>
            <Tooltip title="New ticket 🚀">
                <Ticket size={20} className='primary'
                    onClick={() => {
                        setAddTicket(true)
                        ticketForm.setFieldValue('timestamp', dayjs())
                        ticketForm.setFieldValue('currency', round?.currency ?? 'usd')


                    }}
                    style={{
                        cursor: 'pointer'
                    }}

                />
            </Tooltip>
            {user?.integrations?.google?.id_token && <Tooltip title="Send an email">
                <Envelope size={20} className='primary'
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setAddEmail(true)
                        emailForm.setFieldValue('timestamp', dayjs())
                        emailForm.setFieldValue('to', touchpoint?.name)
                        if (touchpoint?.contact?.includes('@')) {
                            emailForm.setFieldValue('email', touchpoint?.contact)
                        }
                    }}
                />
            </Tooltip>}

            {
                !touchpoint?.relation?.includes('angels') && <Tooltip title="Add person">
                    <UserPlus size={20} className='primary'
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={async () => {
                            dispatch(openModal('addPersonModal'))

                        }}
                    />
                </Tooltip>
            }
            <Tooltip title="Extract conversations from Email">
                <CircleNotch size={20} className='primary'
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={async () => {
                        try {
                            // console.log(touchpoint);
                            // return
                            setLoading(true)
                            const resp = await checkemailswithcontacts({ touchpointId: touchpoint.id })
                            // console.log(resp);

                            setLoading(false)
                        } catch (error) {
                            console.log(error);
                            setLoading(false)

                        }
                    }}

                />
            </Tooltip>
            <Popconfirm
                title="Are you sure you want to delete this touchpoint?"
                onConfirm={async () => {
                    setLoading(true)
                    try {
                        await deleteDoc(doc(getFirestore(), 'touchpoints', touchpoint.id))
                        message.success('Touchpoint deleted')
                        setLoading(false)
                        dispatch(closeModal('openTouchPointDetails'))
                    } catch (error: any) {
                        notification.error({ message: 'Error deleting touchpoint', description: error.message })
                        setLoading(false)
                    }
                }}
            >
                <Trash size={20} className=''

                    style={{
                        cursor: 'pointer',
                        color: 'red'
                    }}
                />
            </Popconfirm>
        </Space>}
    </>
}


const TouchPointDetails: React.FC = () => {
    // Your component logic here
    const dispatch = useDispatch()
    const { touchpoint } = useSelector((state: any) => state.data)
    const { openTouchPointDetails } = useSelector((state: any) => state.modals)
    const { user } = useSelector((state: any) => state.user)
    const [tpscontacts, setTpsContacts] = useState<any>([])
    const [tpstickets, setTpsTickets] = useState<any>([])
    const [tpspersons, setTpsPersons] = useState<any>([])
    const [loading, setLoading] = useState<string[]>([])

    const checkemailthread = httpsCallable(getFunctions(), 'checkemailthread')

    const getTPSContacts = async () => {
        // const data = await getDocs(query(collection(getFirestore(), 'touchpointsContacts'),
        //     where('touchpointId', '==', touchpoint?.id)
        // ))
        // GET REALTIME DATA
        try {
            onSnapshot(query(collection(getFirestore(), 'touchpointsContacts'), where('touchpointId', '==', touchpoint?.id), orderBy('timestamp', 'desc')), (snapshot) => {
                const contacts = snapshot.docs.map(doc => doc.data())
                setTpsContacts(contacts)
            })
            onSnapshot(query(collection(getFirestore(), 'persons'), where('resourceMembers', 'array-contains', user?.uid), where('relation', '==', touchpoint?.relation)), (snapshot) => {
                const contacts = snapshot.docs.map(doc => doc.data())
                setTpsPersons(contacts)
                dispatch(setData({
                    item: 'touchpoint',
                    data: {
                        ...touchpoint,
                        persons: contacts
                    }
                }))
            })
        } catch (error) {
            console.log(error);

        }
        try {
            onSnapshot(query(collection(getFirestore(), 'tickets'), where('touchpointId', '==', touchpoint?.id), orderBy('timestamp', 'desc')), (snapshot) => {
                const tickets = snapshot.docs.map(doc => doc.data())
                setTpsTickets(tickets)
            })
        } catch (error) {
            console.log(error);

        }
        // const contacts = data.docs.map(doc => doc.data())
        // setTpsContacts((prev: any) => ([...prev, ...contacts]))
        // console.log(contacts);
    }


    useEffect(() => {
        if (openTouchPointDetails && touchpoint?.id) {
            getTPSContacts()
        }
    }, [openTouchPointDetails])


    if (!touchpoint) return <></>


    const timeline = [
        {
            children: <p> Relation started
                <Popover
                    content={
                        <div
                            style={{
                                maxWidth: '220px'
                            }}
                        >
                            {/* <label>Change TP Status</label> */}
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={touchpoint?.relationInitiated ? dayjs(touchpoint.relationInitiated) : dayjs()}
                                onChange={(value) => {
                                    updateDoc(doc(getFirestore(), 'touchpoints', touchpoint.id), {
                                        relationInitiated: value.valueOf()
                                    })
                                    dispatch(setData({
                                        item: 'touchpoint',
                                        data: {
                                            ...touchpoint,
                                            relationInitiated: value.valueOf()
                                        }
                                    }))
                                }}
                            />
                        </div>
                    }
                >
                    <span className='primary' style={{ marginLeft: '5px' }}>{touchpoint?.relationInitiated ? dayjs(touchpoint.relationInitiated).format('MMM DD, YYYY') : ''} </span>
                </Popover>
            </p>,
        },
        {
            children: <p> Status: <Popover content={
                <div
                    style={{
                        maxWidth: '220px'
                    }}
                >
                    {/* <label>Change TP Status</label> */}
                    <Select
                        style={{ width: '100%' }}
                        defaultValue={touchpoint.status}
                        onChange={(value) => {
                            updateDoc(doc(getFirestore(), 'touchpoints', touchpoint.id), {
                                status: value
                            })
                            dispatch(setData({
                                item: 'touchpoint',
                                data: {
                                    ...touchpoint,
                                    status: value
                                }
                            }))
                        }}
                        options={[
                            { label: 'Building Relationship', value: 'Building Relationship' },
                            { label: 'Follow Up', value: 'Follow Up' },
                            { label: 'Pitched', value: 'Pitched' },
                            { label: 'Negotiation', value: 'Negotiation' },
                            { label: 'Passed', value: 'Passed' },
                            { label: 'Deal Closed 🚀', value: 'Deal Closed' },
                        ]}
                    />
                </div>
            }>
                <span className='primary'>{touchpoint.status ?? 'Unkwown'}</span>
            </Popover></p>,
        },

        //set tpscontacts without duplicating id's
    ]

    if (tpscontacts.length > 2) {
        timeline.push({
            children: <p> {tpscontacts.length - 2} more contact events (showing latests)</p>
        })
    }

    for (const contact of tpscontacts.slice(
        0, 2
    ).reverse()


    ) {
        timeline.push({
            children: <p> Contact <Tooltip title={`${contact?.person?.email}`}>
                <span className='primary'>{contact?.person?.name}</span>
            </Tooltip> on <span className='primary'>{dayjs(contact.timestamp).format('MMM DD, YYYY')}</span> via <span className='primary'>{contact.source}</span></p>,
        })
    }

    if (tpstickets.length > 0) {
        for (const ticket of tpstickets) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: ticket.currency,
                minimumFractionDigits: 0
            })
            timeline.push({
                children: <p> New ticket via <span className='primary'>{ticket.vehicle?.toUpperCase()}</span> for <span className='primary'>{formatter.format(ticket.amount)}</span> on <span className='primary'>{dayjs(ticket.timestamp).format('MMM DD, YYYY')}</span></p>,
            })
        }
    }



    const TicketsTb = () => {
        return <div
            style={{
                backgroundColor: '#fafbfd',
                padding: '10px',
                borderRadius: '10px',

                marginTop: '25px'
            }}
        >
            <Typography.Title level={5} style={{ margin: 0, padding: 0, marginBottom: '20px' }}>Tickets 🚀</Typography.Title>
            <Table
                scroll={{ x: 400 }}
                pagination={{
                    hideOnSinglePage: true,
                }}
                columns={[

                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (text: string, record: any) => {
                            const formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: record.currency,
                                minimumFractionDigits: 0
                            })

                            return <Typography.Text style={{
                                fontWeight: 600,
                                color: 'black',
                                fontSize: '12px'
                            }}>{formatter.format(record.amount)}</Typography.Text>
                        }
                    },
                    {
                        title: 'Vehicle',
                        dataIndex: 'vehicle',
                        key: 'vehicle',
                        render: (text: string, record: any) => <Tag color='blue'>{record.vehicle.toUpperCase()}</Tag>
                    },
                    {
                        title: 'Deposited',
                        dataIndex: 'deposited',
                        key: 'deposited',
                        render: (text: string, record: any) => {
                            return <Popover
                                content={
                                    <Select
                                        placeholder="Deposited?"
                                        onChange={(v) => {
                                            updateDoc(doc(getFirestore(), 'tickets', record.id), {
                                                deposited: v
                                            })
                                        }}

                                        options={[
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false }
                                        ]}
                                    />
                                }
                            >
                                {record?.deposited ? <Check style={{ color: 'green' }} size={20} /> : <Clock


                                    style={{ color: 'var(--primary-color)' }} size={20} />}
                            </Popover>
                        }
                    },

                ]} dataSource={tpstickets} />
        </div>
    }

    let personsComposed = [...tpspersons]

    if (touchpoint?.relation?.includes('angels')) {
        personsComposed.push({
            name: touchpoint?.name,
            email: touchpoint?.contact,
            type: ['Angel'],
            id: touchpoint?.relation?.split('/')[1]
        })
    }

    // console.log(personsComposed);


    // AVOID DUPLICATES ON ID 

    personsComposed = personsComposed.filter((person, index, self) =>
        index === self.findIndex((t) => (
            t.id === person.id
        ))
    )


    const PersonsTp = () => {
        return <div style={{
            backgroundColor: '#fafbfd',
            padding: '10px',
            borderRadius: '10px',
            marginTop: '25px'
        }}>
            <Typography.Title level={5} style={{ margin: 0, padding: 0, marginBottom: '20px' }}>Persons</Typography.Title>
            <Table
                scroll={{ x: 400 }}
                pagination={{
                    hideOnSinglePage: true,

                }}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text: string, record: any) => <Typography.Text
                            // onClick={() => {
                            //     dispatch(setData({
                            //         item: 'contactEvent',
                            //         data: { ...record, type: 'touchpoint' }
                            //     }))
                            //     dispatch(openModal('viewContactDetailsModal'))
                            // }}
                            style={{
                                fontWeight: 600,
                                color: 'black',
                                fontSize: '12px'
                            }}>{record?.name}</Typography.Text>
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        render: (text: string, record: any) => <Typography.Text
                            // onClick={() => {
                            //     dispatch(setData({
                            //         item: 'contactEvent',
                            //         data: { ...record, type: 'touchpoint' }
                            //     }))
                            //     dispatch(openModal('viewContactDetailsModal'))
                            // }}
                            style={{
                                fontWeight: 600,
                                color: 'black',
                                fontSize: '12px'
                            }}>{record?.email}</Typography.Text>
                    },
                    {
                        title: 'Person',
                        dataIndex: 'type',
                        key: 'type',
                        render: (text: string, record: any) => <Typography.Text
                            // onClick={() => {
                            //     dispatch(setData({
                            //         item: 'contactEvent',
                            //         data: { ...record, type: 'touchpoint' }
                            //     }))
                            //     dispatch(openModal('viewContactDetailsModal'))
                            // }}
                            style={{
                                fontWeight: 600,
                                color: 'black',
                                fontSize: '12px'
                            }}>{record?.type?.length > 0 ? record?.type[0] : 'Unkown'}</Typography.Text>
                    },

                ]} dataSource={personsComposed} /></div>
    }
    const ContactsTb = () => {
        return <div style={{
            backgroundColor: '#fafbfd',
            padding: '10px',
            borderRadius: '10px'
        }}>
            <Typography.Title level={5} style={{ margin: 0, padding: 0, marginBottom: '20px' }}>Contact events / notes</Typography.Title>
            <Table
                scroll={{ x: 400 }}
                pagination={{
                    hideOnSinglePage: true,

                }}
                expandable={{
                    expandedRowRender: (record) => <div className='d-flex flex-column'>
                        <Typography.Title level={4} style={{ margin: 0, whiteSpace: 'pre-wrap', fontSize: '12px', marginBottom: '5px' }}>Notes on the contact</Typography.Title>
                        <Typography.Text type="secondary" style={{ margin: 0, whiteSpace: 'pre-wrap', fontSize: '11px' }}>{record.notes}</Typography.Text>
                    </div>,
                    rowExpandable: (record) => record.name !== 'Not Expandable',

                }}
                columns={[
                    {
                        title: 'Person',
                        dataIndex: 'person',
                        key: 'person',
                        render: (text: string, record: any) => <Typography.Text
                            onClick={() => {
                                dispatch(setData({
                                    item: 'contactEvent',
                                    data: { ...record, type: 'touchpoint' }
                                }))
                                dispatch(openModal('viewContactDetailsModal'))
                            }}
                            style={{
                                fontWeight: 600,
                                color: 'black',
                                fontSize: '12px'
                            }}>{record?.person?.name}</Typography.Text>
                    },
                    {
                        title: 'Source',
                        dataIndex: 'source',
                        key: 'source',
                        render: (text: string, record: any) => <Tag color='blue'>{record.source.toLowerCase()}</Tag>
                    },

                    {
                        title: 'Emotional Stamina',
                        dataIndex: 'emotionalStamina',
                        key: 'emotionalStamina',
                        render: (text: string, record: any) => <Rate
                            disabled
                            value={record.emotionalStamina}
                            style={{
                                color: 'var(--primary-color)',
                            }}
                            character={({ index = 0 }) => customIcons[index + 1]}
                        />
                    },
                    {
                        title: 'Date',
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        render: (text: string, record: any) => <Typography.Text style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px'
                        }}>{dayjs(record.timestamp).format('MMM DD, YYYY')}</Typography.Text>
                    },
                    {
                        title: 'Relations',
                        dataIndex: 'relation',
                        key: 'relation',
                        render: (text: string, record: any) => {
                            if (text?.includes('emails')) return loading.includes(record.id) ? <Spin /> : <Tooltip title="Open conversation">
                                <Envelope
                                    onClick={async () => {
                                        try {
                                            setLoading((prev) => ([...prev, record.id]))
                                            const result: any = await checkemailthread({ ...record })
                                            console.log(result.data);
                                            setLoading((prev) => prev.filter((id) => id !== record.id))
                                            dispatch(setData(
                                                {
                                                    item: 'conversation',
                                                    data: result?.data?.messages || []
                                                }
                                            ))
                                            dispatch(openModal('openConversationModal'))
                                        } catch (error) {
                                            message.error('There was an error opening the conversation')
                                        }
                                    }}

                                    size={18} style={{ color: 'var(--primary-color)', cursor: 'pointer' }} />
                            </Tooltip>
                            else return <></>
                        }
                    }

                ]} dataSource={tpscontacts} /></div>
    }


    return (
        <Drawer
            open={openTouchPointDetails}
            width={'100%'}
            onClose={() => {
                dispatch(closeModal('openTouchPointDetails'))
                dispatch(setData({
                    item: 'touchpoint',
                    data: {}
                }))
            }}
            title={<Row justify="center">
                <Col xs={24} lg={20}>
                    <Row justify="space-between" align="middle">
                        <p>Touchpoint details with <span className='primary'>{touchpoint?.name}</span></p>
                        <div>
                            <Tag
                                color='blue'
                                style={{ borderRadius: '8px' }}
                            >{touchpoint?.lastContact ? `Last contact ${dayjs(touchpoint?.lastContact).format('MMM DD YYYY')}` : 'You should contact'}</Tag>
                        </div>
                    </Row>
                </Col>
            </Row>}



        >
            <Row justify="center">
                <Col xs={24} lg={20} style={{
                    borderBottom: '1px solid #cecece',
                    marginBottom: '30px'
                }}>
                    <Row align="middle" justify="space-between">
                        <div className=''
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '20px'
                            }}
                        >
                            <Typography.Title level={4} style={{ margin: 0, padding: 0 }}
                                className='primary'
                            >{touchpoint?.name}</Typography.Title>
                            <Typography.Text type="secondary" style={{}}>{touchpoint?.contact}</Typography.Text>
                        </div>
                        <AddTouchPointContact />
                    </Row>
                </Col>
                <Col xs={24} lg={20}>

                    <Row gutter={24}>
                        <Col xs={24} lg={6}
                            style={{
                                borderRight: '1px solid #cecece'
                            }}
                        >
                            <Timeline
                                // pending="Waiting for new events..."
                                style={{ marginTop: '15px' }}
                                items={timeline}


                            />
                        </Col>
                        <Col xs={24} lg={18}>
                            <ContactsTb />
                            <PersonsTp />
                            <TicketsTb />
                        </Col>



                    </Row>
                </Col>
            </Row>
        </Drawer>
    );
};

export default TouchPointDetails;