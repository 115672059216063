import { CircleDashed } from '@phosphor-icons/react';
import { Result, Typography } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect, useNavigate } from 'react-router-dom';
import { setUser } from '../context/userSlice';

const GoogleCallback: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state: any) => state.user);
    const dispatch = useDispatch()
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log(code);
        if (user.gcode !== code) {
            updateDoc(doc(getFirestore(), 'users', user.uid), {
                gcode: code
            })
                .finally(() => {
                    console.log('saved');

                    dispatch(setUser({ ...user, gcode: code }))
                })
        } else navigate('/vcs');



        // Perform any necessary actions with the extracted code

        // const timer = setTimeout(() => {
        //     // Perform any necessary actions after 50 seconds
        //     redirect('/vcs');
        // }, 50000);

        // return () => clearTimeout(timer);
    }, []);

    return (
        <Result
            status="warning"
            icon={<CircleDashed size={55} className="primary" />}
            title="Amazing, we are almost there!"
            extra={
                <Typography.Text type="secondary">Please wait a few seconds until we re-generate</Typography.Text>
            }
        />
    );
};

export default GoogleCallback;