import React, { useState } from 'react';
import { Row, Col, Alert, Typography, Input, Select, Button, Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import { setUser } from '../context/userSlice';
import { useFirestoreCollectionData } from 'reactfire';
import { openModal } from '../context/modalsSlice';
import { Plus } from '@phosphor-icons/react';


const RoundsTable = () => {
    const { user } = useSelector((state: any) => state.user)
    const round = user?.rounds[0] ?? null

    const { status, data } = useFirestoreCollectionData(query(collection(getFirestore(), 'rounds'), where('resourceMembers', 'array-contains', user.uid)))

    const [search, setSearch] = useState('')

    const dispatch = useDispatch()








    return <Col xs={24}>

        <Row justify="space-between" align="middle">
            <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>Rounds</Typography.Title>
            <Row gutter={12}>
                <Input.Search
                    style={{ flex: 1, marginRight: '5px' }}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Select
                    mode="tags"
                    style={{
                        maxWidth: '350px',
                        minWidth: '200px',
                        flex: 1,
                        display: window.innerWidth < 900 ? 'none' : 'block'
                    }}


                    placeholder="Categoría" options={[
                        //ADD TYPES OF FUNDS 
                        { label: 'Pre-Seed', value: 'preseed' },
                        { label: 'Seed', value: 'seed' },
                        { label: 'Pre-Series A', value: 'preseriesa' },
                        { label: 'Series A', value: 'seriesa' },
                        { label: 'Series B', value: 'seriesb' },

                    ]} />
                {data?.filter((r) => r.status === 'active').length <= 0 && <Button onClick={() => {
                    dispatch(openModal('newFund'))
                }} shape="circle" type="primary" style={{ marginLeft: '10px' }} icon={<Plus size={20} />} />}
            </Row>
        </Row>
        <Table
            scroll={{ x: 400 }} columns={[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text: string, record: any) => <a href={record
                        .link} target="_blank">{<Typography.Text style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px'
                        }}>{text}</Typography.Text>}</a>
                },
                {
                    title: 'CAP',
                    dataIndex: 'cap',
                    key: 'cap',
                    render: (text: string, record: any) => {

                        const formatteer = Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: record.currency ?? 'USD',
                            minimumFractionDigits: 0
                        })
                        return <Typography.Text style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px'
                        }}>{formatteer.format(record.cap)}</Typography.Text>
                    }
                },
                {
                    title: 'Raising',
                    dataIndex: 'target',
                    key: 'target',
                    render: (text: string, record: any) => {

                        const formatteer = Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: record.currency ?? 'USD',
                            minimumFractionDigits: 0
                        })
                        return <Typography.Text style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px'
                        }}>{formatteer.format(record.target)}</Typography.Text>
                    }
                },
                {
                    title: 'Closed',
                    dataIndex: 'closed',
                    key: 'closed',
                    render: (text: string, record: any) => {

                        const formatteer = Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: record.currency ?? 'USD',
                            minimumFractionDigits: 0
                        })
                        return <Typography.Text style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px'
                        }}>{formatteer.format(record.closed)}</Typography.Text>
                    }
                }, {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text: string, record: any) => {
                        return <Tag color={
                            record.status === 'active' ? 'green' :
                                record.status === 'closed' ? 'red' :
                                    record.status === 'pending' ? 'blue' :
                                        'geekblue'
                        }>{text}</Tag>
                    }
                }

            ]} dataSource={data?.filter((vc: any) => vc?.name?.toLowerCase().includes(search?.toLowerCase()))


            } />
    </Col >

}

const Rounds: React.FC = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)



    return (
        <Row>
            {!user.settings?.removeRoundsNotice && <Col xs={24} style={{ marginBottom: '6px' }}>
                <Alert message="Your current opened rounds. We wish you the best." type="info" closable onClose={async () => {

                    await updateDoc(doc(getFirestore(), 'users', user.uid), { "settings.removeRoundsNotice": true })
                    dispatch(setUser({ ...user, settings: { ...user.settings, removeRoundsNotice: true } }))
                }} />
            </Col>
            }
            <RoundsTable />
            <Col span={12}>
                {/* Content for the second column */}
            </Col>
        </Row>
    );
};

export default Rounds;