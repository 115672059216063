import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Row, Col, Typography, Button } from 'antd';
import { closeModal, openModal } from '../context/modalsSlice';
import { Check, Pencil, SignOut, UserCircle, X } from '@phosphor-icons/react';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { setUser } from '../context/userSlice';
import { getFunctions, httpsCallable } from 'firebase/functions';

const UserDetailsDrawer = () => {
    const auth = getAuth()
    const { user } = useSelector((state: any) => state.user);
    const { showUserDetails } = useSelector((state: any) => state.modals);
    const dispatch = useDispatch()
    const connecttogoogle = httpsCallable(getFunctions(), 'connecttogoogle')
    const [loading, setLoading] = useState<string[]>([])


    const getConnection = async () => {
        setLoading((p) => [...p, 'google'])
        const url = await connecttogoogle();
        console.log(url.data);

        window.open(url?.data as string, '_blank')
        setLoading((p) => p.filter((i) => i !== 'google'))


    }
    return (
        <Drawer
            // SHOW ON LEFT SIDE

            title={<Row align="middle" justify="space-between">
                <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>User Details</Typography.Title>
                <X size={18} style={{ cursor: 'pointer' }} onClick={() => dispatch(closeModal('showUserDetails'))} />
            </Row>}
            placement="left"
            closable={false}
            open={showUserDetails}
            onClose={() => dispatch(closeModal('showUserDetails'))}
            style={{ padding: 0 }}
            bodyStyle={{ padding: '0px 15px' }}
            width={400}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                //space between
                justifyContent: 'space-between',
                height: '100%'
            }}>
                <Row>
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <Typography.Title level={5} style={{ margin: 0, padding: 0, color: '#cecece' }}>Hello, <Typography.Title className='primary' level={5} style={{ margin: 0, padding: 0 }} editable={{
                            icon: <Pencil size={15} className='primary' />,
                            tooltip: 'Edit your name',
                            onChange: async (e) => {
                                await updateDoc(doc(getFirestore(), 'users', user.uid), {
                                    name: e
                                })
                                dispatch(setUser({ ...user, name: e }))
                            },
                        }}>
                            {user?.name}
                        </Typography.Title> </Typography.Title>


                        <Button
                            loading={loading.includes('google')}
                            style={{ marginTop: '20px' }}
                            icon={
                                user?.integrations?.google ? <Check size={15} /> : <></>
                            }
                            onClick={() => {
                                if (user?.integrations?.google) return
                                getConnection()
                            }}>{user?.integrations?.google ? 'Connected to google' : 'Access to google'}</Button>

                        <Button
                            loading={loading.includes('google')}
                            style={{ marginTop: '20px' }}
                            icon={
                                user?.integrations?.google ? <UserCircle size={15} /> : <></>
                            }
                            onClick={() => {
                                dispatch(openModal('showSettings'))
                            }}>Settings</Button>
                    </Col>

                </Row>
                <Row>
                    <Col xs={24}
                        style={{ borderTop: '1px solid #f0f0f0', marginTop: '20px', padding: '10px 5px' }}
                    >
                        <Row justify="space-between" align="middle">
                            <Typography.Text style={{ padding: 0, margin: '0', color: '#cecece', fontSize: '11px' }} type='secondary'>v1.0 scarranca + gigstack.pro</Typography.Text>
                            <SignOut size={19} style={{ cursor: 'pointer', color: '#333' }}

                                onClick={() => {
                                    signOut(auth)
                                }} />
                        </Row>
                    </Col>
                </Row>

            </div>
            {/* Add more user details here */}
        </Drawer>
    );
};

export default UserDetailsDrawer;