import React, { useState } from 'react';
import { Modal, Row, Col, Form, Input, Select, Typography, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../context/modalsSlice';
import { X } from '@phosphor-icons/react';
import { v4 as uuidv4 } from 'uuid'
import { doc, getFirestore, setDoc } from 'firebase/firestore';

const AddPersonModal: React.FC = () => {
    // Your component logic goes here
    const { addPersonModal } = useSelector((state: any) => state.modals)
    const { user } = useSelector((state: any) => state.user)
    const { touchpoint } = useSelector((state: any) => state.data)


    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const FinishForm = async (values: any) => {
        const id = uuidv4()
        const person = {
            ...values,
            id,
            timestamp: new Date().valueOf(),
            resourceMembers: [user?.uid],
            owner: user?.uid,
            relation: touchpoint.relation

        }

        //DELETE UNDEFINED KEYS
        Object.keys(person).forEach((key) => (person[key] === undefined) && delete person[key]);

        try {
            setLoading(true)
            await setDoc(doc(getFirestore(), 'persons', id), person)
            dispatch(closeModal("addPersonModal"))
            message.success('Person added')
            form.resetFields()
            setLoading(false)
        } catch (error: any) {
            message.error('Error adding person ' + error?.message)
            setLoading(false)
        }

    }
    return (
        <Modal
            style={{ top: 20 }}
            title={null}
            footer={null}
            open={addPersonModal}
            closable={false}
            closeIcon={<span></span>}
            onCancel={() => dispatch(closeModal("addPersonModal"))}
        >
            <Form
                initialValues={{}}
                onFinish={FinishForm}
                form={form}
                layout='vertical'
            >
                <Row gutter={16}>
                    <Col xs={24} lg={24} style={{ marginBottom: '15px' }}>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={5} style={{
                                margin: '0px',
                                padding: '0px'
                            }}>Add a new person</Typography.Title>
                            <X size={20}
                                style={{ cursor: 'pointer' }}
                                onClick={() => dispatch(closeModal("addPersonModal"))} />
                        </Row>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Name" name="name"
                            rules={[{
                                required: true,
                                message: 'Please input a name'
                            }]}

                        >
                            <Input placeholder='Jane Doe' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Email" name="email" rules={[{
                            required: true,
                            message: 'Please input a name'
                        }]}>
                            <Input placeholder='Jane.Doe@gmail.com' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={24}>
                        <Form.Item name="type" label="Type of contact">
                            <Select mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Select investor type"
                                maxCount={1}
                                options={[
                                    { label: 'Managing Partner', value: 'Managing Partner' },
                                    { label: 'Partner', value: 'Partner' },
                                    { label: 'Principal', value: 'Principal' },
                                    { label: 'Associate', value: 'Associate' },
                                    { label: 'Analyst', value: 'Analyst' },
                                    { label: 'Other', value: 'Other' }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item label="Notes" name="notes">
                            <Input.TextArea placeholder='Notes about this person' />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Button loading={loading} type="primary" htmlType="submit" block style={{ width: '100%' }}>Add Person</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddPersonModal;