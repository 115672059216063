import React, { useState } from 'react';
import { Row, Col, Modal, Typography, Avatar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../context/modalsSlice';
import dayjs from 'dayjs';
import { useAuth } from 'reactfire';
import { CaretLeft, X } from '@phosphor-icons/react';



const ConversationModal = () => {
    const [message, setMessage] = useState<any>(null)
    const dispatch = useDispatch()
    const { openConversationModal } = useSelector((state: any) => state.modals);
    const { conversation, touchpoint } = useSelector((state: any) => state.data);
    const { user } = useSelector((state: any) => state.user);
    const auth = useAuth()

    const mess = [...(conversation ?? [])]
    const messages = mess?.sort((a: any, b: any) => {
        return Number(a.internalDate) < Number(b.internalDate) ? 1 : -1
    })

    const profilePic = auth.currentUser?.photoURL

    const RenderStep = () => {

    }



    const Message = (c: any) => {
        const headers = c.payload?.headers
        const to = headers.find((h: any) => h.name === 'From')?.value
        const toSubs = to.split(' <')[0]?.replace(/"/g, '')
        const from = headers.find((h: any) => h.name === 'From')?.value
        const fromEmail = from.split(' <')[1]?.replace('>', '')
        const meAsSender = fromEmail?.includes(user.email)

        const snippet = c?.snippet
        const date = dayjs(Number(c.internalDate)).format('DD/MM/YYYY HH:mm')
        const subject = headers.find((h: any) => h.name === 'Subject')?.value
        return <Col xs={24} key={c.id} style={{
            display: 'flex', flexDirection: 'column', borderBottom: '1px solid #cecece', paddingBottom: '15px',
            cursor: 'pointer'
        }} onClick={() => {
            setMessage(c)

        }}>
            <Row justify="space-between">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '10px',
                    flex: 1
                }}>
                    <Avatar
                        src={meAsSender ? profilePic : null}
                        style={{
                            backgroundColor: meAsSender ? '#19DB8A' : '#FFB5C0',
                            verticalAlign: 'middle'
                        }}
                    >{toSubs[0]}</Avatar>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '10px',
                        flex: 1
                    }}>
                        <Typography.Text style={{ fontSize: '13px', margin: '0', padding: '0' }} >{toSubs}</Typography.Text>
                        <Typography.Text style={{ fontSize: '11px', margin: '0', padding: '0' }} type="secondary">{fromEmail}</Typography.Text>
                    </div>
                </div>

                <Typography.Text style={{ fontSize: '11px', margin: '0', padding: '0' }} type="secondary">{date}</Typography.Text>
            </Row>

            <Typography.Text style={{ fontSize: '13px', margin: '0', padding: '0', marginLeft: '52px', marginTop: '10px', fontWeight: 'bold' }} >{subject}</Typography.Text>
            <Typography.Text style={{ fontSize: '12px', margin: '0', padding: '0', marginLeft: '52px', marginTop: '10px' }} >{snippet?.substring(
                0,
                message ? 10000 : 50
            )}{!message && "..."}</Typography.Text>
        </Col>
    }


    return (
        <Modal open={openConversationModal}
            style={{
                top: 20
            }}
            closable={false}
            closeIcon={<></>}
            onCancel={() => {
                dispatch(closeModal('openConversationModal'))
            }} footer={null}>
            <Row gutter={[16, 16]} style={{ marginTop: '25px' }}>
                <Col xs={24} style={{ marginBottom: '25px' }}>
                    <Row justify="space-between">
                        <Row justify="start" align="middle">
                            {message && <CaretLeft size={14} style={{ marginRight: '5px', cursor: 'pointer' }}
                                onClick={() => {
                                    setMessage(null)
                                }}
                            />}
                            <Typography.Text
                                type='secondary'
                                style={{ fontSize: '10px' }}
                            >{!message ? 'Messages in the conversation' : 'Email Details'}</Typography.Text>
                        </Row>
                        <X size={14} style={{ cursor: 'pointer' }} onClick={() => {
                            dispatch(closeModal('openConversationModal'))
                        }} />
                    </Row>
                </Col>
                {message ? <Message {...message} /> : (messages ?? [])?.map((c: any) => {



                    return <Message {...c} />
                })}
            </Row>
        </Modal>
    );
};

export default ConversationModal;