import { createSlice } from '@reduxjs/toolkit'

interface ModalsState {
    newFund: boolean
}

const initialState: ModalsState = {
    newFund: false
}

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openModal: (state: any, action) => {
            state[action.payload] = true
        },
        closeModal: (state: any, action) => {
            state[action.payload] = false
        },
        setElementSelected: (state: any, action) => {
            state[action.payload.element] = action.payload.data
        }
    }
})

export const { openModal, closeModal, setElementSelected } = modalsSlice.actions

export default modalsSlice.reducer