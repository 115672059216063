import React, { FunctionComponent, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SuspenseWithPerf, useSigninCheck } from "reactfire";
import AuthScreensWrapper from "./AuthScreenWrapper";
import LoginPage from "./Login";
import { Button, Layout, Progress, Row, Typography, Form, Modal, Input, InputNumber, Col, Alert, Select, DatePicker, message, Menu, MenuProps, Drawer, Result, Flex, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Buildings, Circle, CircleDashed, GoogleLogo, HandTap, MapTrifold, Money, Plus, Tray, Trophy, User, X } from "@phosphor-icons/react";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { setUser } from "../context/userSlice";
import Funds, { NewFundToList } from "./Funds";
import { closeModal, openModal } from "../context/modalsSlice";
import Touchpoints from "./Touchpoints";
import NewTouchPointModal from "./NewTouchPointModal";
import TouchPointDetails from "./TouchpointDetails";
import Angels from "./Angels";
import NewAngelModal from "./NewAngelModal";
import Rounds from "./Rounds";
import CurrentRoundDetails from "./CurrentRoundDetails";
import UserDetailsDrawer from "./UserDetailsDrawer";
import GoogleCallback from "./GoogleCallback";
import { setData } from "../context/dataSlice";
import InvestorModal from "./InvestorModal";
import ContactEventDetailsModal from "./ContactEventDetailsModal";
import Company from "./Company";
import AddPersonModal from "./AddPersonModal";
import ConversationModal from "./ConversationModal";
import { getFunctions, httpsCallable } from "firebase/functions";
import SettingsModal from "./SettingsModal";


type AuthWrapperProps = {
    children: JSX.Element;
    fallback: JSX.Element;
};

export const AuthWrapper: FunctionComponent<AuthWrapperProps> = ({ children, fallback }) => {
    const { data: signInCheckResult } = useSigninCheck();
    // console.log('', signInCheckResult)

    if (!children) {
        throw new Error('Children must be provided');
    }

    if (signInCheckResult?.signedIn === true) {
        return children
    } else {
        return fallback;
    }
};




const CreateNewFundModal = () => {
    const dispatch = useDispatch()
    const { newFund } = useSelector((state: any) => state.modals)

    return <Drawer
        open={newFund}
        width={"50%"}
        title={<Row align="middle" justify="space-between">
            <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>Add VC</Typography.Title>
            <X onClick={() => dispatch(closeModal('newFund'))} size={20} style={{ cursor: 'pointer' }} />
        </Row>}
        closable={false}
        closeIcon={null}
        footer={null}
        onClose={() => dispatch(closeModal('newFund'))}


    >
        <NewFundToList />
    </Drawer>
}



const CreateNewRound = () => {
    const { user } = useSelector((state: any) => state.user)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [roundInfo, setRoundInfo] = useState<any>({})
    const [form] = Form.useForm()
    const cap = roundInfo.cap ?? 0
    const discount = roundInfo.discount ?? 0
    const target = roundInfo.target ?? 0
    const dilution = discount > 0 ? ((target * 100) / (cap / (discount ?? 1))) :
        ((target) / (cap))
    const dilPercentage = dilution * 100
    return <Form
        onFinish={async (values) => {
            const round = {
                ...values,
                status: values.status[0],
                resourceMembers: [
                    user?.uid
                ],
                closingDate: values.closingDate ? values.closingDate.valueOf() : null,
                timestamp: new Date().valueOf()
            }
            const id = uuidv4();
            try {
                setLoading(true)
                await setDoc(doc(getFirestore(), 'rounds/' + id), { ...round, id })
                dispatch(setUser({
                    ...user,
                    rounds: [
                        ...user.rounds,
                        { ...round, id }
                    ]

                }))
                setLoading(false)
                dispatch(closeModal('newRound'))
            } catch (error: any) {
                message.error('Error creating round: ' + error.message)
                setLoading(false)
            }



        }}
        form={form}
        layout="vertical"
        onValuesChange={(changedValues, allValues) => {
            setRoundInfo((prev: any) => {
                return {
                    ...prev,
                    ...changedValues
                }
            })
        }}

        initialValues={{
            currency: 'USD',
            status: ['active']
        }}

    >
        <div style={{ marginTop: '15px' }}></div>
        <Row gutter={12}>
            <Col xs={24}>
                <Form.Item label="Round Name" name="name" rules={[
                    {
                        required: true,
                        message: 'Please add a value'
                    }]}>
                    <Input placeholder="PreSeed" />
                </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
                <Form.Item label="Raising amount" name="target" rules={[
                    {
                        required: true,
                        message: 'Please add a value'
                    }]}>
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="$200,000"
                        step={1000}

                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    // formatter={(value: any) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    //     isNaN(value) ? 0 : value
                    // )}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
                <Form.Item label="CAP" name="cap" rules={[
                    {
                        required: true,
                        message: 'Please add a value'
                    }]}>
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="1,000,000"
                        step={1000}
                        formatter={(value) => `$ ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                </Form.Item>
            </Col>

            <Col xs={24}>
                <Form.Item label="Currency" name="currency" rules={[
                    {
                        required: true,
                        message: 'Please add a value'
                    }]}>
                    <Select
                        options={[
                            { label: 'USD', value: 'USD' },
                            { label: 'EUR', value: 'EUR' },
                            { label: 'GBP', value: 'GBP' },
                            { label: 'MXN', value: 'MXN' }
                        ]}
                    />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item label="Round Status" name="status" rules={[
                    {
                        required: true,
                        message: 'Please add a value'
                    }]}>
                    <Select
                        mode="tags"
                        maxCount={1}

                        options={[
                            { label: 'Active', value: 'active' },
                            { label: 'Closed', value: 'closed' },
                            { label: 'OnHold', value: 'onhold' },
                        ]}
                    />
                </Form.Item>
            </Col>


            {!isNaN(dilPercentage) && <Col xs={24} lg={24} style={{ marginBottom: '15px' }}>
                <Alert message={
                    <Typography.Text type="secondary">{`The round will dilute ${dilPercentage.toFixed(4)}%`}</Typography.Text>
                } type="info" showIcon />
            </Col>}

            <Col xs={24} lg={24}>
                <Form.Item label="Closing Date" name="closingDate">
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
            </Col>

            <Col xs={24}>
                <Button loading={
                    loading
                } type="primary" htmlType="submit" block>Create Round</Button>
            </Col>
        </Row>



    </Form>
}


const RoundModal = () => {
    const { newRound } = useSelector((state: any) => state.modals)
    const dispatch = useDispatch()
    return <Modal open={newRound}
        style={{ top: 10 }}
        onCancel={() => {
            dispatch(closeModal('newRound'))
        }}
        footer={null}
        title={null}
    >
        <Row align="middle">
            <div style={{
                borderRadius: '50%',
                border: '3px solid #19DB8A',
                width: 20,
                height: 20,

            }}>
            </div>
            <Typography.Title level={5} style={{ margin: 0, marginLeft: '5px', }}>Create a new round</Typography.Title>
        </Row>
        <CreateNewRound

        />
    </Modal>
}

const MainRoutes = () => {
    const navigate = useNavigate()


    // const [createRound, setCreateRound] = useState(false)


    const { user } = useSelector((state: any) => state.user)
    const { collapsed } = useSelector((state: any) => state.data)
    const round = user.rounds ? user.rounds[0] : null
    const dispatch = useDispatch()


    const ProgressBar = () => {
        const percent = ((round?.closed ?? 0) * 100) / (round?.target ?? 0)


        return <div
            onClick={() => {
                console.log(user);

            }}
            style={{
                position: 'relative',
                width: '30px',
                height: '30px',
            }}
        >
            <Trophy size={15}
                color="#333333"
                style={{
                    position: 'absolute',
                    //align center
                    top: 8,
                    left: 8,


                }} />
            <Progress type="circle" status="active" strokeColor="#19DB8A" percent={percent} size={30} showInfo={false} style={{
                position: 'absolute',
                top: 0,
                right: 0,

            }} />

        </div>
    }

    const formatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: round?.currency ?? 'USD',
    })

    const RoundHeader = () => {

        if (!round) {
            return <Button type="primary" shape="circle"
                onClick={() => {
                    dispatch(openModal('newRound'))
                }}
                icon={
                    <Plus size={20} />
                }></Button>
        }

        return <Row style={{ marginTop: '0px' }} align="middle">

            <div
                onClick={() => {
                    dispatch(openModal('showRoundDetails'))
                }}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '10px',
                    cursor: 'pointer'
                }}>
                <Typography.Text type="secondary"
                    style={{
                        fontSize: '10px'
                    }}
                >Your current round</Typography.Text>
                <Typography.Text style={{ margin: 0, padding: 0, color: '#OFOFOF', fontSize: '11px' }}> {round?.name}</Typography.Text>
                <Typography.Text type="secondary"
                    style={{
                        fontSize: '10px'
                    }}
                >{formatter.format(round.closed ?? 0)} / {formatter.format(round.target ?? 0)}</Typography.Text>

            </div>
            <ProgressBar />
        </Row>
    }





    const LayoutHeader = () => {
        return <Layout.Header
            style={{
                width: '100vw',
                overflow: 'hidden',
                padding: '5px 25px',
                minHeight: '100px',
                borderBottom: '1px solid #f0f0f0',
                position: 'fixed',
                zIndex: 100,
                // backgroundColor: 'blue'
            }}
        >
            <RoundModal />
            <div style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                //center 
                justifyContent: "center"
            }}>
                <Row justify="space-between"
                    align="middle"
                    style={{
                        padding: '0',

                        // backgroundColor: 'yellow',
                    }}
                >
                    <div style={{
                        display: 'flex',
                        padding: 0,
                        margin: '0',
                        flexDirection: 'column',
                    }}>
                        <Typography.Text type="secondary"
                            style={{
                                fontSize: '10px'
                            }}
                        >Welcome back!</Typography.Text>
                        <Typography.Title level={5} style={{ margin: 0, padding: 0, color: '#OFOFOF' }}
                            onClick={() => {
                                dispatch(openModal('showUserDetails'))
                            }}
                        > {user?.name}</Typography.Title>
                    </div>

                    <RoundHeader />
                </Row>

            </div>
        </Layout.Header>
    }






    const items2: MenuProps['items'] = [
        {
            key: `touchpoints`,
            icon: <HandTap size={20} />,
            label: `Touchpoints`,
            onClick: () => {
                navigate('/touchpoints')
            }


        },
        {
            key: `vcs`,
            icon: <Money size={20} />,
            label: `VC's`,
            onClick: () => {
                navigate('/vcs')
            }


        },
        {
            key: `angels`,
            icon: <Circle size={20} />,
            label: `Angels`,
            onClick: () => {
                navigate('/angels')
            }


        },
        {
            key: `rounds`,
            icon: <Tray size={20} />,
            label: `My Rounds`,
            onClick: () => {
                navigate('/rounds')
            }


        },
        {
            key: `company`,
            icon: <Buildings size={20} />,
            label: `My Company`,
            onClick: () => {
                navigate('/company')
            }
        }
    ];

    const connecttogoogle = httpsCallable(getFunctions(), 'connecttogoogle')
    const [loading, setLoading] = useState<string[]>([])
    const getConnection = async () => {
        setLoading((p) => [...p, 'google'])

        try {
            const url = await connecttogoogle();
            console.log(url.data);

            //copy to clipboard 

            navigator.clipboard.writeText(url?.data as string);
            message.success('URL copied to clipboard')
            window.open(url?.data as string, '_blank')
            setLoading((p) => p.filter((i) => i !== 'google'))
        } catch (error: any) {
            message.error('Error connecting to google: ' + error?.message)
        }


    }
    items2.push({
        key: `divider`,
        type: 'divider',

    })
    if (!user?.integrations?.google) {
        // ADD A DIVIDER

        items2.push({
            disabled: loading.includes('google'),

            key: `google`,
            icon: loading?.includes('google') ? <Spin size="small" /> : <GoogleLogo size={20} />,
            label: `Connect to Gmail`,
            onClick: () => getConnection()
        })
    } else {
        items2.push({
            disabled: true,
            key: `google`,
            icon: <GoogleLogo size={20} />,
            label: `Connected`,

            onClick: () => {

            }
        })
    }
    return (
        <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={<>loading</>}>
            <AuthWrapper fallback={
                <Routes>
                    <Route path="*" element={<LoginPage />} />
                </Routes >

            }>



                {/* <>PASE AUTH </> */}
                <AuthScreensWrapper>

                    <Layout
                        style={{
                            minHeight: '100vh',
                            backgroundColor: '#fafbfd',

                        }}
                    >
                        <SettingsModal />
                        <CreateNewFundModal />
                        <NewTouchPointModal />
                        <TouchPointDetails />
                        <NewAngelModal />
                        <UserDetailsDrawer />
                        <InvestorModal />
                        <ContactEventDetailsModal />
                        {round?.id && <CurrentRoundDetails />}
                        <CreateNewFundModal />
                        <AddPersonModal />
                        <ConversationModal />

                        <LayoutHeader />


                        {round?.id ? <Layout
                            style={{
                                marginTop: '90px'
                            }}
                        >
                            <Layout.Sider width={200}
                                collapsible={true}
                                onCollapse={(v) => {
                                    dispatch(setData({
                                        item: 'collapsed',
                                        data: v
                                    }))
                                }}
                                collapsedWidth={
                                    0
                                }
                                style={{
                                    background: '#fafbfd',
                                    position: 'fixed',
                                    zIndex: 100,
                                    height: '100vh',
                                }}>
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={[
                                        window.location.pathname.split('/')[1]
                                    ]}

                                    style={{ height: '100%', borderRight: 0, }}
                                    items={items2}
                                />
                            </Layout.Sider>
                            <Layout style={{
                                padding: '0 24px 24px', backgroundColor: '#fafbfd',
                                marginLeft: collapsed ? '' : '200px'
                            }}>

                                <Layout.Content
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        marginTop: '30px',
                                        minHeight: 280,
                                        backgroundColor: '#fafbfd'

                                    }}
                                >
                                    <Routes>


                                        <Route path="/touchpoints" element={<Touchpoints />} />
                                        <Route path="/angels" element={<Angels />} />
                                        <Route path="/rounds" element={<Rounds />} />
                                        <Route path="/vcs" element={<Funds />} />
                                        <Route path="/google/callback" element={<GoogleCallback />} />
                                        <Route path="/company" element={<Company />} />
                                        <Route path="*" element={<Touchpoints />} />






                                    </Routes >
                                </Layout.Content>
                            </Layout>
                        </Layout>

                            :

                            <Result
                                status="warning"
                                icon={<CircleDashed size={55} className="primary" />}
                                title="Please create an open round first."
                                extra={
                                    <Button type="primary" key="console" onClick={() => dispatch(openModal('newRound'))}>
                                        Create Round
                                    </Button>
                                }
                            />

                        }

                    </Layout>
                </AuthScreensWrapper >


            </AuthWrapper >
        </SuspenseWithPerf >
    )
}

export default MainRoutes;