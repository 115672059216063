import React, { useState } from 'react';
import { Row, Col, Typography, Input, Select, Button, Table, Tag, Space, Alert } from 'antd';
import { useAuth, useFirestoreCollectionData } from 'reactfire';
import { collection, doc, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../context/modalsSlice';
import { HandTap, Plus } from '@phosphor-icons/react';
import { setData } from '../context/dataSlice';
import { setUser } from '../context/userSlice';


const AngelsTable = () => {
    const auth = useAuth()

    const { status, data } = useFirestoreCollectionData(query(collection(getFirestore(), 'angels'), where('resourceMembers', 'array-contains', auth?.currentUser?.uid)))

    const [search, setSearch] = useState('')

    const dispatch = useDispatch()



    const { user } = useSelector((state: any) => state.user)
    const round = user?.rounds[0] ?? null




    return <Col xs={24}>

        <Row justify="space-between" align="middle">
            <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>Angels</Typography.Title>
            <Row gutter={12}>
                <Input.Search
                    style={{ flex: 1, marginRight: '5px' }}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Select
                    mode="tags"
                    style={{
                        maxWidth: '350px',
                        minWidth: '200px',
                        flex: 1,
                        display: window.innerWidth < 900 ? 'none' : 'block'
                    }}

                    placeholder="Categoría" options={[
                        //ADD TYPES OF FUNDS 
                        { label: 'Pre-Seed', value: 'preseed' },
                        { label: 'Seed', value: 'seed' },
                        { label: 'Pre-Series A', value: 'preseriesa' },
                        { label: 'Series A', value: 'seriesa' },
                        { label: 'Series B', value: 'seriesb' },

                    ]} />
                <Button onClick={() => {
                    dispatch(openModal('addAngelModal'))
                }} shape="circle" type="primary" style={{ marginLeft: '10px' }} icon={<Plus size={20} />} />
            </Row>
        </Row>
        <Table
            scroll={{ x: 400 }}


            columns={[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text: string, record: any) => <Typography.Text onClick={() => {
                        dispatch(setData({
                            item: 'investor',
                            data: { ...record, type: 'vc', resource: `angels/${record.id}` }
                        }))
                        dispatch(openModal('viewInvestorModal'))
                    }}
                        style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px',
                            cursor: 'pointer'
                        }}
                    >{text}</Typography.Text>
                },
                {
                    title: 'Stage',
                    dataIndex: 'stage',
                    key: 'stage',
                    render: (text: string, record: any) => {
                        return record.stage?.map((stage: string) => <Tag key={stage}>{stage}</Tag>)
                    }
                },
                {
                    title: 'Actions',
                    dataIndex: 'link',
                    key: 'link',
                    render: (text: string, record: any) => <Space>


                        <HandTap size={20}
                            className='primary'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                dispatch(openModal('newTouchPointModal'))
                                dispatch(setData({ item: 'investor', data: { ...record, type: 'vc', resource: `angels/${record.id}` } }))
                            }}
                        />

                    </Space>
                }
            ]} dataSource={data?.filter((vc: any) => vc?.name?.toLowerCase().includes(search?.toLowerCase()))


            } />
    </Col >

}

const Angels: React.FC = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)



    return (
        <Row>
            {!user.settings?.removeAngelsNotice && <Col xs={24} style={{ marginBottom: '6px' }}>
                <Alert message="Angels are for security reasons private resources and only you can see them." type="info" closable onClose={async () => {

                    await updateDoc(doc(getFirestore(), 'users', user.uid), { "settings.removeAngelsNotice": true })
                    dispatch(setUser({ ...user, settings: { ...user.settings, removeFundsPublicNotice: true } }))
                }} />
            </Col>
            }
            <AngelsTable />
            <Col span={12}>
                {/* Content for the second column */}
            </Col>
        </Row>
    );
};

export default Angels;