import React, { useState } from 'react';
import { Modal, Button, Row, Col, Table, Typography, message, Popover, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../context/modalsSlice';
import { useAuth, useFirestoreCollectionData } from 'reactfire';
import { collection, doc, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
//IMPORT UUID
import { v4 as uuidv4 } from 'uuid';
import { Pencil } from '@phosphor-icons/react';


const SettingsModal: React.FC = () => {
    const dispatch = useDispatch();
    const { showSettings } = useSelector((state: any) => state.modals);
    const auth = useAuth()
    const { status, data } = useFirestoreCollectionData(query(collection(getFirestore(), 'hooks'), where('resourceMembers', 'array-contains-any', [auth.currentUser?.uid])))

    const [loadingNewHook, setLoadingNewHook] = useState(false)

    return (
        <>

            <Modal
                title="Account Settings"
                open={showSettings}
                footer={null}

                onCancel={() => dispatch(closeModal('showSettings'))}
            >

                <Row>
                    <Col xs={24} style={{ marginTop: '25px' }}>
                        <Row justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
                            <Typography.Text style={{
                                fontSize: '12px',
                                fontWeight: 'bold'

                            }}>Webhooks</Typography.Text>
                            <Button type="primary" size='small' style={{ marginLeft: 'auto' }}
                                loading={loadingNewHook}
                                onClick={async () => {
                                    setLoadingNewHook(true)
                                    try {
                                        const id = uuidv4()
                                        await setDoc(doc(getFirestore(), 'hooks', id), {
                                            resourceMembers: [auth.currentUser?.uid],
                                            owner: auth.currentUser?.uid,
                                            status: 'pending',
                                            url: '',
                                            timestamp: moment().valueOf(),
                                            id
                                        })
                                    } catch (error: any) {
                                        message.error(`An error occurred while adding a new webhook, "${error?.message}"`)
                                    }
                                    setLoadingNewHook(false)
                                }}

                            >Add Webhook</Button>
                        </Row>
                        <Table
                            columns={[
                                {
                                    title: 'Hook URL',
                                    dataIndex: 'url',
                                    key: 'url',
                                    render: (text: any, record) => {
                                        //RENDER AN ANT DESIGN INPUT WITH EDITABLE AND MUST BE A URL
                                        return <Typography.Text
                                            type={text?.length <= 0 ? "secondary" : undefined}
                                            editable={{
                                                onChange: (e) => {
                                                    const isUrl = RegExp(/^(http|https):\/\/[^ "]+$/).test(e)
                                                    if (!isUrl) {
                                                        return message.error('Please enter a valid URL')
                                                    }
                                                    updateDoc(doc(getFirestore(), 'hooks', record.id), {
                                                        url: e
                                                    })
                                                },


                                            }}>{text?.length <= 0 ? 'Add url' : text}</Typography.Text>
                                    },
                                },
                                {
                                    title: 'Status',
                                    dataIndex: 'status',
                                    key: 'status',
                                    render: (text: any, record) => {
                                        return <Popover content={
                                            <Select defaultValue={text} options={[
                                                { label: 'Active', value: 'active' },
                                                { label: 'Inactive', value: 'inactive' },
                                                // { label: 'Pending', value: 'pending' },
                                            ]}
                                                onChange={async (e) => {
                                                    await updateDoc(doc(getFirestore(), 'hooks', record.id), {
                                                        status: e
                                                    })
                                                }}

                                            />
                                        }>
                                            <Typography.Text>{text} <Pencil /></Typography.Text>
                                        </Popover>
                                    }
                                },


                            ]
                            }
                            dataSource={data}
                            rowKey="id"
                            loading={status === 'loading'}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default SettingsModal;