import React, { useState } from 'react';
import { Card, Button, Typography, Row, message } from 'antd';
import { GoogleLogo } from '@phosphor-icons/react';
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';




const LoginPage = () => {

    const auth = getAuth();
    const [loading, setLoading] = useState<any>(null);


    const MakeGoogleSignIn = async () => {
        setLoading('google');

        setTimeout(() => {
            const provider = new GoogleAuthProvider();

            provider.addScope('profile');
            provider.addScope('email');

            signInWithPopup(auth, provider)
                .then(async (result) => {
                    const user = result.user;
                    return user;
                })
                .catch((error) => {
                    setLoading(null);
                    return message.error(error?.message || 'Error al iniciar sesión');
                });
        }, 300);
    };


    const HandleGoogleLogin = () => {
        console.log('login with google')

    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: "#fafbfd" }}>
            <Card style={{ width: 300 }}>
                <Row justify="center">
                    <div style={{
                        borderRadius: '50%',
                        border: '5px solid #19DB8A',
                        width: 40,
                        height: 40,
                        marginBottom: '20px'
                    }}>

                    </div>
                </Row>
                <Typography.Title style={{ margin: 0, marginBottom: '5px', textAlign: 'center' }} level={3}>Hola, Bonjour, Hello</Typography.Title>
                <Typography.Text type="secondary" style={{
                    display: 'block',
                    textAlign: 'center',
                    marginBottom: '20px'
                }}>Sign in to continue</Typography.Text>
                <Button type="default"
                    loading={loading === 'google'}
                    onClick={MakeGoogleSignIn}
                    style={{
                        // marginTop: '25px'
                    }} block icon={
                        <GoogleLogo size={15} />
                    }>
                    Login with Google
                </Button>
                <Typography.Text style={{
                    display: 'block',
                    color: '#cecece',
                    textAlign: 'center',
                    marginTop: '10px',
                    fontSize: '11px'
                }}>By continuing with the app you accept the <a href="https://drive.google.com/file/d/11351SBouZ4H5aNLUOaTJ7k3Yn6jJ86Zi/view?usp=sharing" target='_blank' >terms and privacy</a> </Typography.Text>
                <Typography.Text style={{
                    display: 'block',
                    color: '#cecece',
                    textAlign: 'center',
                    marginTop: '10px',
                    fontSize: '11px'
                }}>V1.0</Typography.Text>
            </Card>
        </div>
    );
};

export default LoginPage;