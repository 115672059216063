import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tooltip, Form, Input, DatePicker, Select, Timeline, Button, message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../context/modalsSlice';
import { CircleWavyQuestion } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import ConfettiExplosion from 'react-confetti-explosion';
import { v4 as uuidv4 } from 'uuid';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { setData } from '../context/dataSlice';


const NewTouchPointModal = () => {
    // Your component logic here

    const { user } = useSelector((state: any) => state.user)
    const round = user?.rounds?.length > 0 ? user?.rounds[0] : null

    const dispatch = useDispatch()
    const { newTouchPointModal } = useSelector((state: any) => state.modals)
    const { investor } = useSelector((state: any) => state.data)
    const [touchPForm, setTouchPForm] = useState<any>({})
    const [isExploding, setIsExploding] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        if (newTouchPointModal) {
            form.setFieldsValue({
                name: investor?.name,
                contact: investor?.email ?? investor?.link,
                relationInitiated: dayjs()
            })
            setTouchPForm((prev: any) => ({ ...prev, ...form.getFieldsValue() }))
        }
    }, [newTouchPointModal])



    return (
        <Drawer
            width={'80%'}
            open={newTouchPointModal}
            onClose={() => {
                dispatch(closeModal('newTouchPointModal'))
            }}
            title={<Row justify="space-between" align="middle">
                <p>New touchpoint with <span className='primary'>{investor?.name}</span> </p>
                <Tooltip title="A touch point is the path between the first conversation and the decision of the investor.">
                    <CircleWavyQuestion
                        size={20}
                    />
                </Tooltip>
            </Row>}
        >

            <Form
                onFinish={async (values) => {
                    const id = uuidv4()
                    const preparedData = {
                        id,
                        ...values,
                        resourceMembers: [user?.uid],
                        relation: investor.resource,
                        relationInitiated: values.relationInitiated.valueOf(),
                        tickets: [],
                        timestamp: dayjs().valueOf(),
                        roundId: round.id,
                    }

                    //REMOVE UNDEFINED FIELDS
                    Object.keys(preparedData).forEach((key) => preparedData[key] === undefined && delete preparedData[key])

                    setLoading(true)
                    try {
                        await setDoc(doc(getFirestore(), 'touchpoints', id), preparedData)
                        setIsExploding(true)
                        setTimeout(() => {
                            notification.success({
                                message: 'Touchpoint created',
                                description: 'The touchpoint has been created successfully'
                            })
                            form.resetFields()
                            setLoading(false)
                            setIsExploding(false)
                            dispatch(closeModal('newTouchPointModal'))
                            dispatch(openModal('openTouchPointDetails'))
                            dispatch(setData({
                                item: 'touchpoint',
                                data: preparedData
                            }))
                        }, 2000)
                    } catch (error: any) {
                        setLoading(false)
                        message.error('An error occured while creating touchpoint ' + error.message)
                    }

                    console.log(preparedData)


                }}
                onValuesChange={(changedValues, allValues) => {
                    setTouchPForm((prev: any) => ({ ...prev, ...changedValues }))
                }}
                form={form}
                layout='vertical'
                initialValues={{
                    name: investor?.name
                }}
            >
                <Row gutter={12}>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Investor Name" name="name">
                            <Input readOnly placeholder="Investor Name" />
                        </Form.Item>
                        {/* Your content here */}
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Investor Contact" name="contact">
                            <Input readOnly placeholder="Investor Contact" />
                        </Form.Item>
                        {/* Your content here */}
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Relation Inititated" name="relationInitiated">
                            <DatePicker style={{ width: '100%' }}
                                format={'ddd MMM DD YYYY'}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Status" name="status">
                            <Select style={{ width: '100%' }} placeholder="Select Status" options={[
                                { label: 'Building Relationship', value: 'Building Relationship' },
                                { label: 'Follow Up', value: 'Follow Up' },
                                { label: 'Pitched', value: 'Pitched' },
                                { label: 'Negotiation', value: 'Negotiation' },
                                { label: 'Passed', value: 'Passed' },
                                { label: 'Deal Closed 🚀', value: 'Deal Closed' },
                            ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <label>History</label>
                        <Timeline
                            pending="Waiting for new events..."
                            style={{ marginTop: '15px' }}
                            items={[
                                {
                                    children: `Relation started ${touchPForm.relationInitiated ? dayjs(touchPForm.relationInitiated).format('MMM DD, YYYY') : ''}`,
                                },
                                {
                                    children: `Status: ${touchPForm.status ?? 'Unkwown'}`,
                                },
                            ]}


                        />
                    </Col>
                    <>{isExploding && <ConfettiExplosion
                        zIndex={10000}
                        force={0.8}
                        particleCount={250}

                        width={window.innerWidth}

                    />}</>
                    <Col xs={24}>
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType='submit'

                            block>Create touchpoint</Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};

export default NewTouchPointModal;