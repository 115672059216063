import React, { useState } from 'react';
import { Row, Col, Table, Select, Typography, Form, Modal, Button, Drawer, Input, Switch, message, notification, Tag, Space, Tooltip, Alert, Spin } from 'antd';
import { Article, Backspace, GoogleChromeLogo, HandTap, LinkedinLogo, Plus, X } from '@phosphor-icons/react';
import { v4 as uuidv4 } from 'uuid';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useFirestoreCollectionData } from 'reactfire';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../context/modalsSlice';
import { setUser } from '../context/userSlice';
import { setData } from '../context/dataSlice';

export const NewFundToList = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const auth = getAuth()
    const [form] = Form.useForm()
    return <Col xs={24}>
        <Form
            onFinish={async (values) => {
                if (!auth?.currentUser) return message.error('You need to be logged in to publish a VC')
                const id = uuidv4();
                const prepared = {
                    ...values,
                    id,
                    public: true,
                    publisher: auth?.currentUser.uid,
                    timestamp: new Date().valueOf()
                }
                //remove undefined values
                Object.keys(prepared).forEach(key => prepared[key] === undefined && delete prepared[key])
                try {
                    setLoading(true)
                    await setDoc(doc(getFirestore(), 'vcs', id), prepared)
                    notification.success({ message: 'VC Published', description: 'The VC has been published, thanks.' })
                    setLoading(false)
                    form.resetFields()
                    dispatch(closeModal('newFund'))
                    // close()
                } catch (error: any) {
                    setLoading(false)
                    message.error('Error publishing VC ' + error?.message ?? '')
                }
            }}
            form={form}
            layout="vertical"
            initialValues={{ public: true }}
        >
            <Row gutter={12}>
                <Col xs={24}>
                    <Form.Item label="VC Name" name={'name'} rules={[{ required: true, message: 'Please input the name of the VC' }]}>
                        <Input placeholder='SEQUOIA ' />
                    </Form.Item>
                </Col>
                <Col xs={24} >
                    <Form.Item label="Website" name={'link'} rules={[{ required: true, type: 'url', message: 'Add a valid url' }]}>
                        <Input placeholder='https://____ ' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item label="Email Contact" name={'principalEmail'} rules={[{ required: false, type: 'email', message: 'Add a valid email' }]}>
                        <Input placeholder='contact@SEQUOIA.com ' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item label="LinkedIn" name={'linkedIn'} rules={[{ required: false, type: 'url', message: 'Add a valid url' }]}>
                        <Input placeholder='linkedin/in/___ ' />
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Form.Item label="Stage" name="stage">
                        <Select
                            options={[
                                { label: 'Pre-Seed', value: 'preseed' },
                                { label: 'Seed', value: 'seed' },
                                { label: 'Pre-Series A', value: 'preseriesa' },
                                { label: 'Series A', value: 'seriesa' },
                                { label: 'Series B', value: 'seriesb' },
                            ]} mode="tags" placeholder="Stage" />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <label>Publish as public</label>
                    <Row align="middle" style={{ marginTop: '10px' }}>
                        <Form.Item label="" name="public" valuePropName="checked" style={{ margin: 0, padding: 0 }}>
                            <Switch disabled />
                        </Form.Item>
                        <Typography.Text style={{ flex: 1, marginLeft: '10px' }} type="secondary">Public vc's are visible to all users, we are building this to make the community better</Typography.Text>
                    </Row>
                </Col>
                <Col xs={24}
                    style={{ marginTop: '20px' }}
                >
                    <Button loading={loading} type="primary" htmlType="submit" block>Publish VC</Button>
                </Col>
            </Row>

        </Form>
    </Col>

}


const FundsTable = () => {


    const { status, data } = useFirestoreCollectionData(query(collection(getFirestore(), 'vcs'), where('public', '==', true)))

    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState<string[]>([])

    const dispatch = useDispatch()



    const { user } = useSelector((state: any) => state.user)
    const round = user?.rounds[0] ?? null




    return <Col xs={24}>

        <Row justify="space-between" align="middle">
            <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>VC's</Typography.Title>
            <Row gutter={12}>
                <Input.Search
                    style={{ flex: 1, marginRight: '5px' }}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Select
                    mode="tags"
                    style={{
                        maxWidth: '350px',
                        minWidth: '200px',
                        flex: 1,
                        display: window.innerWidth < 900 ? 'none' : 'block'
                    }}

                    placeholder="Categoría" options={[
                        //ADD TYPES OF FUNDS 
                        { label: 'Pre-Seed', value: 'preseed' },
                        { label: 'Seed', value: 'seed' },
                        { label: 'Pre-Series A', value: 'preseriesa' },
                        { label: 'Series A', value: 'seriesa' },
                        { label: 'Series B', value: 'seriesb' },

                    ]} />
                <Button onClick={() => {
                    dispatch(openModal('newFund'))
                }} shape="circle" type="primary" style={{ marginLeft: '10px' }} icon={<Plus size={20} />} />
            </Row>
        </Row>
        <Table
            scroll={{ x: 400 }}
            //allow change page size 

            pagination={{

                showTotal: (total, range) => <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{`${range[0]}-${range[1]} of ${total} vcs`}</Typography.Text>,
                showSizeChanger: true,
            }}
            columns={[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text: string, record: any) => <Typography.Text

                        onClick={() => {
                            dispatch(setData({
                                item: 'investor',
                                data: { ...record, type: 'vc', resource: `vcs/${record.id}` }
                            }))
                            dispatch(openModal('viewInvestorModal'))
                        }}
                        style={{
                            fontWeight: 600,
                            color: 'black',
                            fontSize: '12px',
                            cursor: 'pointer'
                        }}>{text}</Typography.Text>
                },
                {
                    title: 'Stage',
                    dataIndex: 'stage',
                    key: 'stage',
                    render: (text: string, record: any) => {
                        return record.stage?.map((stage: string) => <Tag key={stage}>{stage}</Tag>)
                    }
                },
                {
                    title: 'Actions',
                    dataIndex: 'link',
                    key: 'link',
                    render: (text: string, record: any) => <Space>
                        {record.link && <a href={record.link} target="_blank"><GoogleChromeLogo size={20} /></a>}
                        {record.linkedIn && <a href={record?.linkedIn} target="_blank" style={{
                            color: 'black'

                        }}><LinkedinLogo size={20} /></a>}
                        {/* {!round?.id ? <></> : !round?.watching?.includes(record.id) ? <Tooltip title="Add to watchlist">
                        <Article size={20} className='primary'
                            onClick={async () => {
                                const watching = [...(round?.watching ?? [])]
                                console.log(watching, record.id);

                                watching.push(record.id)
                                dispatch(setUser({ ...user, rounds: [{ ...round, watching }] }))
                                await updateDoc(doc(getFirestore(), 'rounds', round.id), { watching })
                            }}
                        />
                    </Tooltip> : <Tooltip title="Remove from watchlist"><Backspace

                        onClick={async () => {
                            const watching = round?.watching ?? []
                            const newWatching = watching.filter((id: string) => id !== record.id)
                            dispatch(setUser({ ...user, rounds: [{ ...round, watching: newWatching ?? [] }] }))
                            await updateDoc(doc(getFirestore(), 'rounds', round.id), { watching: newWatching })
                        }}
                        size={20} /></Tooltip>} */}

                        <Tooltip title="Start a touchpoint">
                            {loading.includes(record.id) ? <Spin /> : <HandTap size={20}
                                className='primary'
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    setLoading([...loading, record.id])
                                    const started = await getDocs(query(collection(getFirestore(), 'touchpoints'), where('resourceMembers', 'array-contains-any', [user?.uid]), where('relation', '==', `vcs/${record.id}`)))
                                    if (!started.empty) {
                                        console.log('already started');

                                        dispatch(openModal('openTouchPointDetails'))
                                        dispatch(setData({
                                            item: 'touchpoint',
                                            data: started.docs[0].data()
                                        }))
                                        setLoading(loading.filter((id) => id !== record.id))
                                        return
                                    }
                                    dispatch(openModal('newTouchPointModal'))
                                    dispatch(setData({ item: 'investor', data: { ...record, type: 'vc', resource: `vcs/${record.id}` } }))
                                    setLoading(loading.filter((id) => id !== record.id))
                                }}
                            />}
                        </Tooltip>

                    </Space>
                }
            ]} dataSource={data?.filter((vc: any) => vc?.name?.toLowerCase().includes(search?.toLowerCase()))


            } />
    </Col >

}









const Funds: React.FC = () => {

    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.user)



    return (
        <Row>
            {!user.settings?.removeFundsPublicNotice && <Col xs={24} style={{ marginBottom: '6px' }}>
                <Alert message="VC's are public resources, the intention of this is to generate a lot for the community." type="info" closable onClose={async () => {

                    await updateDoc(doc(getFirestore(), 'users', user.uid), { "settings.removeFundsPublicNotice": true })
                    dispatch(setUser({ ...user, settings: { ...user.settings, removeFundsPublicNotice: true } }))
                }} />
            </Col>
            }
            <FundsTable />
            <Col span={12}>
                {/* Content for the second column */}
            </Col>
        </Row>
    );
};

export default Funds;