import './App.css';


import { AnalyticsProvider, AuthProvider, FirestoreProvider, StorageProvider, useFirebaseApp, useInitPerformance } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { ConfigProvider } from 'antd';
import { getAuth } from 'firebase/auth';

import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import moment from 'moment';

import dayjs from 'dayjs';

import MainRoutes from './app/AuthWrapper';


function App() {

  const app = useFirebaseApp();

  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);
  const analyticssdk = getAnalytics(app)

  useInitPerformance(async (app) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(app);
  })

  // const { data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
  //   const remoteConfig = getRemoteConfig(firebaseApp);
  //   remoteConfig.settings = {
  //     minimumFetchIntervalMillis: 10000,
  //     fetchTimeoutMillis: 10000,
  //   };
  //   await fetchAndActivate(remoteConfig);
  //   return remoteConfig;
  // });





  /*APP*/

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>

        <StorageProvider sdk={storage}>
          {/* <RemoteConfigProvider sdk={remoteConfigInstance}> */}
          <AnalyticsProvider sdk={analyticssdk}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#19DB8A',
                  borderRadius: 4,

                },
                components: {
                  Input: {
                    controlHeight: 36,
                    colorTextLabel: '#474D66',

                  },

                  Form: {
                    colorTextLabel: '#474D66',

                  },
                  Layout: {
                    colorBgContainer: 'white',
                    colorBgHeader: 'white',
                    colorBgBody: 'white',
                    colorBgLayout: 'white',

                  },
                  Button: {
                    fontFamily: 'Inter',
                    fontFamilyCode: 'Inter',
                  }



                }
              }}

            >
              <MainRoutes />
            </ConfigProvider>
          </AnalyticsProvider>
          {/* </RemoteConfigProvider> */}
        </StorageProvider>

      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
